import { del, get, set } from "idb-keyval";

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 */
export function createIDBPersister(idbValidKey = "reactQuery") {
  return {
    persistClient: async (client) => {
      const data = JSON.stringify(client);
      await set(idbValidKey, data);
    },
    restoreClient: async () => {
      const data = await get(idbValidKey);
      return data ? JSON.parse(data) : data;
    },
    removeClient: async () => {
      await del(idbValidKey);
    },
  };
}
