import { Button, Icon, Popup, TableCell, TableRow } from "semantic-ui-react";
import { style } from "../../screens/Sidekick";

export function getSentimentIcon(rating) {
  switch (rating) {
    case 1:
      return { name: "frown", color: "#e85858" };
    case 2:
      return { name: "frown", color: "#ca98d9" };
    case 3:
      return { name: "meh", color: "#98b6d9" };
    case 4:
      return { name: "smile", color: "#58b9b7" };
    case 5:
      return { name: "smile", color: "#5cb958" };
    default:
      return null;
  }
}

const CallTranscriptionRow = ({
  id,
  status,
  hasSummary,
  summary,
  sentiment,
  number,
  start,
  duration,
  setInfoModal,
}) => {
  const sentimentIcon = getSentimentIcon(sentiment?.rating);

  return (
    <TableRow>
      <TableCell>{summary}</TableCell>
      <TableCell textAlign="center">
        {sentiment && sentiment?.rating && (
          <Popup
            wide
            position="bottom center"
            trigger={
              <Icon
                name={sentimentIcon?.name}
                size="big"
                style={{ color: sentimentIcon?.color }}
              />
            }
          >
            {sentiment?.summary}
          </Popup>
        )}
      </TableCell>
      <TableCell>{number}</TableCell>
      <TableCell>{start}</TableCell>
      <TableCell>{duration}</TableCell>
      <TableCell>
        <Button
          style={style.gradientButton}
          disabled={status.value !== "PROCESSED"}
          loading={status.value !== "PROCESSED"}
          onClick={() =>
            setInfoModal({
              open: true,
              id: id,
              tab: hasSummary ? "summary" : "transcription",
            })
          }
        >
          View
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default CallTranscriptionRow;
