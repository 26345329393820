import { sdk } from "@gc/ipecs-web-sdk";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import call1Animation from "../animations/call1.json";
import { postCallAnswered, postCallEnded, postCallStarted } from "../api/calls";
import { lookupByCallerid } from "../api/contacts";
import ActiveSoftphoneCall from "../components/softphone/ActiveSoftphoneCall";
import IncomingSoftphoneCallAlert from "../components/softphone/IncomingSoftphoneCallAlert";
import { closePopupDialler, focusIncomingCall } from "../helpers/clickToDial";
import { convertToE164 } from "../helpers/convertToE164";
import { useAuth } from "./AuthContext";
import { useCallLogs } from "./CallLogsContext";
import { useDevices } from "./DevicesContext";

const callAnimations = [call1Animation];

const SoftphoneContext = React.createContext();
const SoftphoneProvider = ({ children }) => {
  const { call } = sdk;
  const { refetchCallLogs, refetchMissedCallCount } = useCallLogs();
  const { audioInput, audioOutput, videoInput, handleInitDevices } =
    useDevices();

  const [minimized, setMinimized] = useState(false);
  const [numberInvalid, setNumberInvalid] = useState(false);
  const [activeCall, setActiveCall] = useState();
  const [consultationCall, setConsultationCall] = useState();
  const [conferenceCall, setConferenceCall] = useState();
  const [incomingCall, setIncomingCall] = useState();
  const [blindTransferring, setBlindTransferring] = useState(false);
  const [transferNumber, setTransferNumber] = useState("");
  const [calls, setCalls] = useState([]);
  const [activeCallParties, setActiveCallParties] = useState([]);
  const [muted, setMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);
  const [recordingStatus, setRecordingStatus] = useState({
    isRecording: false,
  });
  const [callDirection, setCallDirection] = useState();
  const [callDuration, setCallDuration] = useState();

  const [eventCallPeerRetrieve, setEventCallPeerRetrieve] = useState();

  const [eventCallPeerHold, setEventCallPeerHold] = useState();
  const [eventCallHold, setEventCallHold] = useState();
  const [eventCallRetrieve, setEventCallRetrieve] = useState();
  const [eventConferenceEndReceived, setEventConferenceEndReceived] =
    useState();
  const [eventConferenceJoinReceived, setEventConferenceJoinReceived] =
    useState();
  const [eventConferenceOwnReceived, setEventConferenceOwnReceived] =
    useState();
  const [
    eventConferenceUsersInfoReceived,
    setEventConferenceUsersInfoReceived,
  ] = useState();

  const [eventCallRejected, setEventCallRejected] = useState();
  const [eventOutgoingInvalid, setEventOutgoingInvalid] = useState();
  const [eventCallEnded, setEventCallEnded] = useState();
  const [eventOutgoingAnswered, setEventOutgoingAnswered] = useState();
  const [
    eventOutgoingCallEarlyMediaConnected,
    setEventOutgoingCallEarlyMediaConnected,
  ] = useState();
  const [eventIncomingCallReceived, setEventIncomingCallReceived] = useState();
  const [eventIncomingCallCancelled, setEventIncomingCallCancelled] =
    useState();
  const [eventTransferToReceived, setEventTransferToReceived] = useState();
  const [eventTransferredReceived, setEventTransferredReceived] = useState();
  const [eventRecordingStatusChanged, setEventRecordingStatusChanged] =
    useState();
  const [eventOnUnParkedToReceived, setEventOnUnParkedToReceived] = useState();

  const callAnimationRef = useRef();
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const remoteAudioRef = useRef();

  const { apiUser } = useAuth();

  const handleCloseOverlay = useCallback(
    (ticketToClose) => {
      if (activeCall) {
        const { ticket, localMedia } = activeCall;
        if (ticketToClose === ticket && localMedia && localMedia.stop) {
          localMedia.stop();
        }
      }
      setActiveCall();
      setConsultationCall();
      setTransferNumber("");
      setMuted(false);
      setVideoMuted(false);
      setBlindTransferring(false);
      setNumberInvalid(false);
      refetchCallLogs();
      closePopupDialler();
    },
    [activeCall, refetchCallLogs],
  );

  // prevent refresh if call is ongoing
  useEffect(() => {
    window.onbeforeunload = function () {
      if (activeCall) return false;
    };
  }, [activeCall]);

  // ensure call parties are always set correctly
  // clear them if there is no active call
  useEffect(() => {
    const retrieveCallInfo = async () => {
      try {
        const res = await call.getCallUserInfo(activeCall);
        setActiveCallParties([res]);
      } catch (e) {
        console.log(e);
      }
    };

    if (activeCall) {
      // conference call party info is handled by a different event ()
      if (activeCall.consultationType !== "mainConference") {
        if (activeCall.state === "answered" || activeCall.state === "calling") {
          retrieveCallInfo();
        }
      }
    } else {
      setActiveCallParties([]);
    }
  }, [activeCall, call]);

  // this will stop ringing if an inbound call is answered by another device
  useEffect(() => {
    let incomingCallInterval = undefined;

    if (incomingCall) {
      incomingCallInterval = setInterval(() => {
        call.startKeepAliveCall(incomingCall.ticket).catch(() => {
          clearInterval(incomingCallInterval);
          setIncomingCall();
        });
      }, 2500);
    } else {
      if (incomingCallInterval !== undefined) {
        clearInterval(incomingCallInterval);
      }
    }

    return () => {
      if (incomingCallInterval !== undefined) {
        clearInterval(incomingCallInterval);
      }
    };
  }, [call, incomingCall]);

  useEffect(() => {
    if (eventCallPeerRetrieve) {
      console.log("onCallPeerRetrieve");
      console.log(eventCallPeerRetrieve);
      setActiveCall((activeCall) => ({ ...activeCall, state: "answered" }));
      setEventCallPeerRetrieve();
    }
  }, [eventCallPeerRetrieve, setEventCallPeerRetrieve]);

  useEffect(() => {
    if (eventCallPeerHold) {
      console.log("onCallPeerHold");
      console.log(eventCallPeerHold);
      setActiveCall((activeCall) => ({ ...activeCall, state: "hold" }));
      setEventCallPeerHold();
    }
  }, [eventCallPeerHold, setEventCallPeerHold]);

  useEffect(() => {
    if (eventCallHold) {
      console.log("onCallHold");
      console.log(eventCallHold);
      setActiveCall((activeCall) => ({ ...activeCall, state: "hold" }));
      setEventCallHold();
    }
  }, [eventCallHold, setEventCallHold]);

  useEffect(() => {
    if (eventCallRetrieve) {
      if (eventCallRetrieve.ticket === activeCall?.ticket) {
        setActiveCall((activeCall) => ({
          ...activeCall,
          state: "answered",
          consultationType:
            activeCall.consultationType === "conference" ||
            activeCall.consultationType === "mainConference"
              ? "mainConference"
              : null,
        }));
      }

      setEventCallRetrieve();
    }
  }, [eventCallRetrieve, setEventCallRetrieve, activeCall]);

  useEffect(() => {
    if (eventConferenceEndReceived) {
      setActiveCallParties((callParties) => {
        return [
          ...callParties.filter(function (p) {
            return p.peerId === eventConferenceEndReceived.newPeerId;
          }),
        ];
      });
      setEventConferenceEndReceived();
    }
  }, [eventConferenceEndReceived, setEventConferenceEndReceived]);

  useEffect(() => {
    if (eventConferenceJoinReceived) {
      setEventConferenceJoinReceived();
    }
  }, [eventConferenceJoinReceived, setEventConferenceJoinReceived]);
  useEffect(() => {
    if (eventConferenceOwnReceived) {
      setActiveCall((activeCall) => ({
        ...activeCall,
        consultationType: "mainConference",
      }));
      setEventConferenceOwnReceived();
    }
  }, [eventConferenceOwnReceived, setEventConferenceOwnReceived]);
  useEffect(() => {
    if (eventConferenceUsersInfoReceived) {
      console.log("onConferenceUsersInfoReceived");
      console.log(eventConferenceUsersInfoReceived);
      setActiveCallParties(
        eventConferenceUsersInfoReceived.callUserInfo
          .filter((u) => !u.isMe)
          .map((u) => {
            return {
              ...u,
              peerId: u.userType === "external" ? "EX_" + u.number : u.userId,
              callNumber: u.userType === "external" ? u.number : null,
            };
          }),
      );
      setEventConferenceUsersInfoReceived();
    }
  }, [eventConferenceUsersInfoReceived, setEventConferenceUsersInfoReceived]);

  // setup event listeners
  useEffect(() => {
    call.onCallPeerRetrieve(setEventCallRetrieve);

    call.onCallPeerHold(setEventCallPeerHold);

    call.onCallHold(setEventCallHold);

    call.onCallRetrieve(setEventCallRetrieve);

    call.onConferenceEndReceived(setEventConferenceEndReceived);

    call.onConferenceJoinReceived(setEventConferenceJoinReceived);

    call.onConferenceOwnReceived(setEventConferenceOwnReceived);

    call.onConferenceUsersInfoReceived(setEventConferenceUsersInfoReceived);

    call.onOutgoingCallRejected((event) => {
      console.log("onOutgoingCallRejected");
      console.log(event);
      setEventCallRejected(event.ticket);
    });

    call.onOutgoingCallInvalidNumber((event) => {
      console.log("onOutgoingCallInvalidNumber");
      console.log(event);
      setEventOutgoingInvalid(event.ticket);
    });

    call.onCallEnded((event) => {
      console.log("onCallEnded");
      console.log(event);
      setEventCallEnded(event.ticket);
    });

    call.onOutgoingCallAnswered((event) => {
      console.log("onOutgoingCallAnswered");
      console.log(event);
      setEventOutgoingAnswered(event.ticket);
    });

    call.onOutgoingCallEarlyMediaConnected((event) => {
      console.log("onOutgoingCallEarlyMediaConnected");
      console.log(event);
      setEventOutgoingCallEarlyMediaConnected(event);
    });

    call.onIncomingCallReceived((event) => {
      console.log("onIncomingCallReceived");
      console.log(event);
      setEventIncomingCallReceived(event);
    });

    call.onRecordingStatusChanged((event) => {
      console.log("onRecordingStatusChanged");
      console.log(event);
      setEventRecordingStatusChanged(event);
    });

    call.onIncomingCallCancelled((event) => {
      console.log("onIncomingCallCancelled");
      console.log(event);
      setEventIncomingCallCancelled(event.ticket);
    });

    call.onTransferToReceived((event) => {
      console.log("onTransferToReceived");
      console.log(event);
      setEventTransferToReceived(event);
    });

    call.onTransferredReceived((event) => {
      console.log("onTransferredReceived");
      console.log(event);
      setEventTransferredReceived(event);
    });

    call.onUnParkedToReceived((event) => {
      console.log("onUnParkedToReceived");
      console.log(event);
      setEventOnUnParkedToReceived(event);
    });
  }, [call]);

  const handleCallAnswered = useCallback(async () => {
    if (callDirection && activeCall?.callNumber) {
      try {
        if (
          callDirection === "OUTBOUND" &&
          apiUser?.record_calls === "INBOUND"
        ) {
          call.stopRecording();
        }
      } catch {
        // do nothing
      }

      try {
        await postCallAnswered({
          direction: callDirection,
          number: activeCall.callNumber,
        });
      } catch {
        // do nothing
      }
    }
  }, [callDirection, activeCall, call, apiUser.record_calls]);

  const handleCallEnded = useCallback(
    async (eventCallEnded) => {
      try {
        await postCallEnded({
          provider_id: activeCall.ticket,
          duration: callDuration,
          direction: callDirection,
          number: activeCall.callNumber,
        });
      } catch {
        // do nothing
      } finally {
        const remainingCalls = calls.filter((c) => c.ticket !== eventCallEnded);

        if (remainingCalls.length >= 1) {
          const newActiveCall = remainingCalls[0];

          try {
            await call.retrieveCall(newActiveCall.ticket);
          } catch (e) {
            console.log(e);
          }

          setActiveCall(newActiveCall);
        } else {
          setActiveCallParties([]);
          setCallDirection();
          setCallDuration();
          handleCloseOverlay();
        }

        setNumberInvalid(false);
      }
    },
    [
      activeCall,
      callDuration,
      callDirection,
      calls,
      call,
      setActiveCall,
      setCallDuration,
      setCallDirection,
      setNumberInvalid,
      handleCloseOverlay,
    ],
  );

  // handle rejected calls
  useEffect(() => {
    if (eventCallRejected) {
      if (activeCall) {
        if (eventCallRejected === activeCall.ticket) {
          toast("Call rejected", { toastId: eventCallRejected, type: "error" });
          if (activeCall.parentTicket) {
            setActiveCall(
              calls.find((c) => c.ticket === activeCall.parentTicket),
            );
          } else {
            setActiveCall();
            handleCloseOverlay(activeCall.ticket);
          }
        }
      }

      // handle rejected conference call
      if (conferenceCall) {
        if (eventCallRejected === conferenceCall.ticket) {
          setActiveCall({
            ...calls.find((c) => c.ticket === conferenceCall.parentTicket),
            state: "answered",
          });
          toast("Call rejected", { toastId: eventCallRejected, type: "error" });
          setConferenceCall();
        }
      }

      if (consultationCall) {
        if (eventCallRejected === consultationCall.ticket) {
          toast(
            "Existing call in on hold, try another number of cancel transfer to retrieve.",
            {
              toastId: `${eventCallRejected}-info`,
              type: "info",
            },
          );

          toast("Transfer call rejected by user", {
            toastId: eventCallRejected,
            type: "error",
          });

          if (consultationCall.localMedia && consultationCall.localMedia.stop)
            consultationCall.localMedia.stop();
          setConsultationCall();
          setTransferNumber("");

          activeCall.remoteMedia &&
            activeCall.remoteMedia.connect &&
            activeCall.remoteMedia.connect(remoteAudioRef.current);
        }
      }

      setCalls((calls) => calls.filter((c) => c.ticket !== eventCallRejected));
      setEventCallRejected();
    }
  }, [
    calls,
    eventCallRejected,
    activeCall,
    consultationCall,
    conferenceCall,
    handleCloseOverlay,
    setConferenceCall,
  ]);

  // handle outgoing invalid
  useEffect(() => {
    if (eventOutgoingInvalid && activeCall) {
      const { ticket } = activeCall;
      if (eventOutgoingInvalid === ticket) {
        setEventOutgoingInvalid();
        setNumberInvalid(true);
        toast("Invalid number", {
          toastId: eventOutgoingInvalid,
          type: "error",
        });
      }
    }

    if (eventOutgoingInvalid && consultationCall) {
      if (eventOutgoingInvalid === consultationCall.ticket) {
        toast(
          "Existing call in on hold, try another number of cancel transfer to retrieve.",
          {
            toastId: `${eventOutgoingInvalid}-info`,
            type: "info",
          },
        );

        toast("Invalid transfer number", {
          toastId: eventOutgoingInvalid,
          type: "error",
        });

        if (consultationCall.localMedia && consultationCall.localMedia.stop)
          consultationCall.localMedia.stop();
        setConsultationCall();
        setTransferNumber("");

        activeCall.remoteMedia &&
          activeCall.remoteMedia.connect &&
          activeCall.remoteMedia.connect(remoteAudioRef.current);
      }
    }
  }, [eventOutgoingInvalid, activeCall, consultationCall, handleCloseOverlay]);

  // handle call ended
  useEffect(() => {
    if (eventCallEnded) {
      if (conferenceCall) {
        if (eventCallEnded === conferenceCall.ticket) {
          setConferenceCall();

          const originalActiveCall = calls.find(
            (c) => c.ticket === conferenceCall.parentTicket,
          );

          setActiveCall({
            ...originalActiveCall,
            state: "answered",
          });
        }
      }

      if (activeCall) {
        if (eventCallEnded === activeCall.ticket) {
          // if activecall has a parent ticket and it still exists in calls, set active call to this
          const parentCall = calls.find(
            (c) => c.ticket === activeCall.parentTicket,
          );

          setActiveCall(parentCall);
        }
      }

      if (consultationCall) {
        if (eventCallEnded === consultationCall.ticket) {
          toast(
            "Existing call in on hold, try another number of cancel transfer to retrieve.",
            {
              toastId: `${eventCallEnded}-info`,
              type: "info",
            },
          );

          toast("Transfer call ended by user", {
            toastId: eventCallEnded,
            type: "error",
          });

          if (consultationCall.localMedia && consultationCall.localMedia.stop)
            consultationCall.localMedia.stop();
          setConsultationCall();
          setTransferNumber("");

          activeCall.remoteMedia &&
            activeCall.remoteMedia.connect &&
            activeCall.remoteMedia.connect(remoteAudioRef.current);
        }
      }

      setCalls((calls) => calls.filter((c) => c.ticket !== eventCallEnded));
      handleCallEnded(eventCallEnded);
      setEventCallEnded();
    }
  }, [
    eventCallEnded,
    activeCall,
    consultationCall,
    handleCloseOverlay,
    handleCallEnded,
    calls,
    conferenceCall,
  ]);

  // handle outgoing answered
  useEffect(() => {
    const handle = async () => {
      if (eventOutgoingAnswered) {
        if (activeCall && eventOutgoingAnswered === activeCall.ticket) {
          const { ticket, mediaType, localMedia, remoteMedia } = activeCall;
          if (eventOutgoingAnswered === ticket) {
            setActiveCall({ ...activeCall, state: "answered" });
            setCalls((calls) => [
              ...calls.filter((c) => c.ticket !== activeCall.ticket),
              { ...activeCall, state: "answered" },
            ]);

            if (mediaType === "video") {
              remoteMedia &&
                remoteMedia.connect &&
                remoteMedia.connect(remoteVideoRef.current);

              localMedia &&
                localMedia.connect &&
                localVideoRef.current &&
                localMedia.connect(localVideoRef.current);
            }

            remoteMedia &&
              remoteMedia.connect &&
              remoteMedia.connect(remoteAudioRef.current);

            try {
              await call.startKeepAliveCall(eventOutgoingAnswered);
            } catch (error) {
              console.log("startKeepAliveCall error:", error);
            }
          }
        }

        // conference call has been answered
        if (conferenceCall && eventOutgoingAnswered === conferenceCall.ticket) {
          setConferenceCall({ ...conferenceCall, state: "answered" });
          setActiveCall({ ...conferenceCall, state: "answered" });
        }
      }
    };

    handle();
    setEventOutgoingAnswered();
  }, [
    eventOutgoingAnswered,
    activeCall,
    consultationCall,
    conferenceCall,
    call,
  ]);

  // handle outgoing call early media connected
  // this is used to get ringing back, busy tones etc
  useEffect(() => {
    if (eventOutgoingCallEarlyMediaConnected && activeCall) {
      if (eventOutgoingCallEarlyMediaConnected.ticket === activeCall.ticket) {
        const { mediaType, remoteMedia, localMedia } = activeCall;

        if (mediaType === "video") {
          remoteMedia &&
            remoteMedia.connect &&
            remoteMedia.connect(remoteVideoRef.current);

          localMedia &&
            localMedia.connect &&
            localVideoRef.current &&
            localMedia.connect(localVideoRef.current);
        }

        remoteMedia &&
          remoteMedia.connect &&
          remoteMedia.connect(remoteAudioRef.current);

        setEventOutgoingCallEarlyMediaConnected();
      }
    }
  }, [eventOutgoingCallEarlyMediaConnected, activeCall]);

  // handle incoming call received
  useEffect(() => {
    const handle = async () => {
      if (!activeCall && eventIncomingCallReceived) {
        setCallDirection("INBOUND");
        setIncomingCall(eventIncomingCallReceived);

        try {
          await call.sendAckOfferCall(eventIncomingCallReceived.ticket);
        } catch {
          // do nothing
        }

        const { ticket, callNumber, mediaType } = eventIncomingCallReceived;

        if (incomingCall?.ticket !== ticket) {
          call
            .getOtherPartyInfo({
              ticket: ticket,
              peerId: eventIncomingCallReceived.peerId,
            })
            .then((res) => {
              const notification = new Notification(
                `Incoming ${mediaType} call`,
                {
                  body: `From ${res.name}`,
                  icon: "https://connect.cosoft.co.uk/favicon-32x32.png",
                },
              );
              notification.onclick = () => window.focus();
            })
            .catch((e) => {
              const e164Number = convertToE164(
                callNumber,
                apiUser?.phone_locality,
              );

              lookupByCallerid(e164Number, 3000)
                .then((data) => {
                  const contact = data?.data?.data;
                  const contactName =
                    contact?.first_name || contact?.last_name
                      ? [contact?.first_name, contact?.last_name].join(" ")
                      : callNumber;

                  const notification = new Notification(
                    `Incoming ${mediaType} call`,
                    {
                      body: `From ${contactName}`,
                      icon: "https://connect.cosoft.co.uk/favicon-32x32.png",
                    },
                  );
                  notification.onclick = () => window.focus();
                })
                .catch(() => {
                  const notification = new Notification(
                    `Incoming ${mediaType} call`,
                    {
                      body: `From ${callNumber}`,
                      icon: "https://connect.cosoft.co.uk/favicon-32x32.png",
                    },
                  );
                  notification.onclick = () => window.focus();
                });
            });

          // Send a message to the Click to Dial extension to optionally focus an open Connect tab
          focusIncomingCall();
        }

        setEventIncomingCallReceived();
      }
    };

    handle();
  }, [call, eventIncomingCallReceived, activeCall, incomingCall, apiUser]);

  // handle incoming call cancelled
  useEffect(() => {
    if (eventIncomingCallCancelled && incomingCall) {
      const { ticket } = incomingCall;
      if (eventIncomingCallCancelled === ticket) {
        setIncomingCall();
        refetchMissedCallCount();
        refetchCallLogs();
        setEventIncomingCallCancelled();
      }
    }
  }, [
    eventIncomingCallCancelled,
    incomingCall,
    refetchMissedCallCount,
    refetchCallLogs,
  ]);

  // handle transfer to received
  useEffect(() => {
    if (eventTransferToReceived && activeCall) {
      if (eventTransferToReceived?.ticket === activeCall?.ticket) {
        const newActiveCall = {
          ...activeCall,
          peerId: eventTransferToReceived.newPeerId,
          roomId: eventTransferToReceived.newRoomId,
          callNumber: eventTransferToReceived.newRemoteNumber,
        };

        setActiveCall(newActiveCall);

        const partyInfo = {
          avatar: undefined,
          name: eventTransferToReceived.newRemoteNumber,
          callNumber: eventTransferToReceived.newRemoteNumber,
        };
        setActiveCallParties((callParties) => [...callParties, partyInfo]);
        setEventTransferToReceived();
      }
    }
  }, [eventTransferToReceived, activeCall]);

  // handle transferred received
  useEffect(() => {
    if (eventTransferredReceived && activeCall) {
      if (eventTransferredReceived?.ticket === activeCall?.ticket) {
        const newActiveCall = {
          ...activeCall,
          peerId: eventTransferredReceived.newPeerId,
          roomId: eventTransferredReceived.newRoomId,
          callNumber: eventTransferredReceived.newRemoteNumber,
        };

        setActiveCall(newActiveCall);
        setEventTransferredReceived();
      }
    }
  }, [eventTransferredReceived, activeCall]);

  // handle recording status changed
  useEffect(() => {
    if (activeCall && eventRecordingStatusChanged) {
      setRecordingStatus(eventRecordingStatusChanged);
    }
  }, [eventRecordingStatusChanged, activeCall]);

  // handle unparked call event
  useEffect(() => {
    if (eventOnUnParkedToReceived && activeCall) {
      if (eventOnUnParkedToReceived?.ticket === activeCall?.ticket) {
        const newActiveCall = {
          ...activeCall,
          peerId: eventOnUnParkedToReceived.newPeerId,
          roomId: eventOnUnParkedToReceived.newRoomId,
          callNumber: eventOnUnParkedToReceived.newRemoteNumber,
        };

        setActiveCall(newActiveCall);
        setEventOnUnParkedToReceived();

        try {
          postCallStarted({
            direction: "OUTBOUND",
            number: eventOnUnParkedToReceived.newRemoteNumber,
          });
        } catch {
          // do nothing
        }
      }
    }
  }, [eventOnUnParkedToReceived, activeCall]);

  const handleMakeCall = async (destinationNumber, mediaType, delay = 0) => {
    setActiveCall({ state: "calling" });

    // check the audio devices selected are actually available
    await handleInitDevices();
    const _devices = await call.getMediaDevicesInfo();
    const _audioDeviceIds = _devices
      .filter((x) => x.kind === "audiooutput")
      .map((x) => x.deviceId);
    const _videoDeviceIds = _devices
      .filter((x) => x.kind === "videoinput")
      .map((x) => x.deviceId);

    if (audioInput) {
      await call.changeAudioInputRequest(
        _audioDeviceIds.includes(audioInput) ? audioInput : undefined,
      );
    }

    if (audioOutput) {
      await call.changeAudioOutputRequest(
        _audioDeviceIds.includes(audioOutput) ? audioOutput : undefined,
      );
    }

    if (videoInput) {
      await call.changeVideoDeviceRequest(
        _videoDeviceIds.includes(videoInput) ? videoInput : undefined,
      );
    }

    startCall(destinationNumber, mediaType, delay);
  };

  const startCall = useCallback(
    async (destinationNumber, mediaType, delay = 0) => {
      setCallDirection("OUTBOUND");

      try {
        setMinimized(false);

        // optionally delay sending the request to ipecs
        if (delay > 0) {
          await new Promise((r) => setTimeout(r, delay));
        }

        const callInfo = await call.makeCall({
          destinationNumber,
          mediaType: mediaType,
        });
        setActiveCall(callInfo);
        setCalls((calls) => [...calls, callInfo]);

        callInfo.remoteMedia &&
          callInfo.remoteMedia.connect &&
          callInfo.remoteMedia.connect(remoteAudioRef.current);

        callInfo.localMedia &&
          callInfo.localMedia.connect &&
          localVideoRef.current &&
          callInfo.localMedia.connect(localVideoRef.current);

        // set a random animation
        callAnimationRef.current =
          callAnimations[Math.floor(Math.random() * callAnimations.length)];

        try {
          postCallStarted({
            direction: "OUTBOUND",
            number: destinationNumber,
          });
        } catch {
          // do nothing
        }

        try {
          const callPartyInfo = await call.getOtherPartyInfo({
            ticket: callInfo.ticket,
            peerId: callInfo.peerId,
          });
          setActiveCallParties((callParties) => [
            ...callParties,
            callPartyInfo,
          ]);
        } catch (e) {
          const callPartyInfo = {
            name: destinationNumber,
            callNumber: destinationNumber,
            peerId: callInfo.callPeerId,
          };
          setActiveCallParties((callParties) => [
            ...callParties,
            callPartyInfo,
          ]);
        }
      } catch (e) {
        switch (e.message) {
          case "E_CALL_MEDIA_ACCESS_ERROR":
            toast("Failed to connect to your media device", { type: "error" });
            break;
          case "E_CALL_PARAM_ERROR":
            toast(`Invalid number | ${destinationNumber}`, { type: "error" });
            break;
          default:
            console.error(e);
            toast(`Failed to call | ${destinationNumber}`, { type: "error" });
        }
      }
    },
    [call],
  );

  const handleHangup = async (ticket) => {
    setActiveCall((activeCall) => ({ ...activeCall, state: "disconnecting" }));
    setActiveCallParties([]);

    try {
      await call.hangupCall(ticket);
      setEventCallEnded(ticket);
    } catch (e) {
      toast(`Unable to terminate call`);
    } finally {
      if (calls.length === 0) {
        handleCloseOverlay(ticket);
      }
    }
  };

  const handleSendDtmf = async (DTMF, ticket) => {
    try {
      await call.sendDTMFReq({ ticket, DTMF: DTMF.toString() });
    } catch (e) {
      console.log(e);
      toast("Failed to send DTMF", { type: "error" });
    }
  };

  const handleBlindTransfer = async (transferNumber) => {
    if (activeCall) {
      const { ticket } = activeCall;

      try {
        setBlindTransferring(true);
        await call.blindTransferCall({ ticket, transferNumber });
        handleCloseOverlay(ticket);
      } catch (e) {
        setBlindTransferring(false);
        toast("Failed to transfer call", { type: "error" });
      }
    }
  };

  const handleConference = async (conferenceNumber) => {
    if (activeCall) {
      const { ticket } = activeCall;

      try {
        const res = await call.consultationCallReq({
          consultationType: "conference",
          ticket: ticket,
          number: conferenceNumber,
        });

        setActiveCallParties([
          { name: conferenceNumber, callNumber: conferenceNumber },
        ]);

        res.remoteMedia &&
          res.remoteMedia.connect &&
          res.remoteMedia.connect(remoteAudioRef.current);

        setCalls((calls) => [
          ...calls.filter((c) => c.ticket !== res.ticket),
          res,
        ]);

        setActiveCall(res);
      } catch (e) {
        console.log(e);

        toast("Failed to conference call", { type: "error" });
      }
    }
  };

  const handleConferenceCancel = async () => {
    if (conferenceCall) {
      try {
        await call.hangupCall(conferenceCall.ticket);
        setConferenceCall();
      } catch (e) {
        // do nothing
      }
    }
  };

  const handleConferenceComplete = async (conferenceCall) => {
    await call.conferenceCompleteReq(conferenceCall.ticket);
  };

  const handleTransfer = async (transferNumber) => {
    if (activeCall) {
      const { ticket } = activeCall;

      try {
        setTransferNumber(transferNumber);
        const res = await call.consultationCallReq({
          consultationType: "transfer",
          ticket: ticket,
          number: transferNumber,
        });

        res.remoteMedia &&
          res.remoteMedia.connect &&
          res.remoteMedia.connect(remoteAudioRef.current);

        setConsultationCall(res);
      } catch (e) {
        console.log(e);
        setConsultationCall();
        setTransferNumber("");
        toast("Failed to transfer call", { type: "error" });
      }
    }
  };

  const handleTransferComplete = async () => {
    if (activeCall) {
      try {
        await call.transferCompleteReq(consultationCall.ticket);
        setConsultationCall();
        handleCloseOverlay(activeCall.ticket);
      } catch (e) {
        toast("Failed to transfer call", { type: "error" });
      }
    }
  };

  const handleTransferCancel = async () => {
    if (activeCall) {
      try {
        const { ticket, parentTicket, localMedia } = consultationCall;
        await call.transferRetrieveReq({
          ticket,
          parentTicket,
        });
        if (localMedia && localMedia.stop) localMedia.stop();
        setConsultationCall();
        setTransferNumber("");

        activeCall.remoteMedia &&
          activeCall.remoteMedia.connect &&
          activeCall.remoteMedia.connect(remoteAudioRef.current);
      } catch (e) {
        toast("Failed to cancel transfer", { type: "error" });
      }
    }
  };

  const handleParkCall = async (parkingLocation) => {
    if (activeCall) {
      const { ticket } = activeCall;

      try {
        await call.parkingCall({ parkingLocation: parkingLocation, ticket });
        handleCallEnded();
        handleCloseOverlay(ticket);
        toast(`Call parked to location ${parkingLocation}`, {
          type: "success",
        });
      } catch (e) {
        console.log(e);
        toast("Failed to park call", { type: "error" });
      }
    }
  };

  const handleUnparkCall = async (parkingLocation) => {
    if (!activeCall) {
      try {
        setMinimized(false);
        const callInfo = await call.unParkingCall({ parkingLocation });
        setActiveCall(callInfo);

        callInfo.localMedia &&
          callInfo.localMedia.connect &&
          localVideoRef.current &&
          callInfo.localMedia.connect(localVideoRef.current);

        // set a random animation
        callAnimationRef.current =
          callAnimations[Math.floor(Math.random() * callAnimations.length)];

        try {
          const partyInfo = await call.getOtherPartyInfo({
            ticket: callInfo.ticket,
            peerId: callInfo.peerId,
          });
          setActiveCallParties((callParties) => [...callParties, partyInfo]);
        } catch (e) {
          // do nothing
        }
      } catch (e) {
        console.log(e);
        toast("Failed to unpark call", { type: "error" });
      }
    }
  };

  const handleAnswerIncoming = async (answerMediaType) => {
    if (incomingCall) {
      try {
        const { ticket, peerId } = incomingCall;
        setActiveCall(incomingCall);
        setCalls((calls) => [...calls, incomingCall]);

        const { localMedia, remoteMedia } = await call.answerCall({
          ticket,
          mediaType: answerMediaType,
        });

        setActiveCall({
          ...incomingCall,
          localMedia,
          remoteMedia,
          state: "answered",
        });

        try {
          if (answerMediaType === "video") {
            remoteMedia &&
              remoteMedia.connect &&
              remoteMedia.connect(remoteVideoRef.current);

            localMedia &&
              localMedia.connect &&
              localVideoRef.current &&
              localMedia.connect(localVideoRef.current);
          }

          remoteMedia &&
            remoteMedia.connect &&
            remoteMedia.connect(remoteAudioRef.current);

          // set a random animation
          callAnimationRef.current =
            callAnimations[Math.floor(Math.random() * callAnimations.length)];

          setIncomingCall();

          await call.startKeepAliveCall(ticket);
        } catch (error) {
          console.log("startKeepAliveCall error:", error);
        }

        try {
          const partyInfo = await call.getOtherPartyInfo({
            ticket: ticket,
            peerId: peerId,
          });
          setActiveCallParties((callParties) => [...callParties, partyInfo]);
        } catch (e) {
          const callPartyInfo = {
            avatar: undefined,
            name: incomingCall.callNumber,
            callNumber: incomingCall.callNumber,
          };
          setActiveCallParties((callParties) => [
            ...callParties,
            callPartyInfo,
          ]);
        }
      } catch (e) {
        setActiveCall();
        toast("Failed to answer call", { type: "error" });
        console.error(e);
        throw e;
      }
    }
  };

  const hndleVmTransferIncoming = async () => {
    if (incomingCall) {
      try {
        await call.vmTransfer({ ticket: incomingCall.ticket });
        setIncomingCall();
      } catch (e) {
        console.log(e);
        toast("Failed to send call to voicemail", { type: "error" });
      }
    }
  };

  const handleRejectIncoming = async () => {
    if (incomingCall) {
      try {
        await call.rejectCall(incomingCall.ticket);
        setIncomingCall();
      } catch (e) {
        // even if the request fails, remove incoming call so the incoming call popup doesn't persist
        setIncomingCall();
        throw e;
      }
    }
  };

  const handleToggleOnHold = async () => {
    if (activeCall) {
      const { ticket } = activeCall;

      try {
        activeCall.state === "hold"
          ? await call.retrieveCall(activeCall.ticket)
          : await call.holdCall(activeCall);
      } catch (e) {
        console.log(e);
        toast("Failed to toggle on hold", { toastId: ticket, type: "error" });
      }
    }
  };

  const handleToggleMuted = async () => {
    if (activeCall) {
      const { ticket, localMedia } = activeCall;

      try {
        muted
          ? await call.unMuteReq({ localMedia, ticket })
          : await call.muteReq({ localMedia, ticket });
        setMuted(!muted);
      } catch (e) {
        toast("Failed to toggle mute", { toastId: ticket, type: "error" });
      }
    }
  };

  const handleToggleRecording = async () => {
    if (activeCall) {
      try {
        recordingStatus.isRecording
          ? await call.stopRecording()
          : await call.startRecording();
      } catch (e) {
        toast("Failed to toggle recording", {
          type: "error",
        });
      }
    }
  };

  const handleToggleVideoMuted = async () => {
    if (activeCall) {
      const { ticket, localMedia } = activeCall;

      try {
        muted
          ? await call.videoUnMuteReq({ localMedia, ticket })
          : await call.videoMuteReq({ localMedia, ticket });
        setVideoMuted(!videoMuted);
      } catch (e) {
        toast("Failed to toggle mute", { toastId: ticket, type: "error" });
      }
    }
  };

  const defaultContext = {
    activeCall,
    consultationCall,
    conferenceCall,
    handleMakeCall,
    minimized,
    setMinimized,
    callAnimationRef,
    remoteAudioRef,
    remoteVideoRef,
    localVideoRef,
    numberInvalid,
    activeCallParties,
    handleHangup,
    handleSendDtmf,
    blindTransferring,
    handleBlindTransfer,
    transferNumber,
    calls,
    handleTransfer,
    handleTransferComplete,
    handleTransferCancel,
    handleConference,
    handleConferenceComplete,
    handleConferenceCancel,
    handleParkCall,
    handleUnparkCall,
    incomingCall,
    handleAnswerIncoming,
    hndleVmTransferIncoming,
    handleRejectIncoming,
    handleToggleOnHold,
    muted,
    handleToggleMuted,
    videoMuted,
    handleToggleVideoMuted,
    recordingStatus,
    handleToggleRecording,
    setCallDirection,
    setCallDuration,
    handleCallEnded,
    handleCallAnswered,
  };

  return (
    <SoftphoneContext.Provider value={defaultContext}>
      {activeCall && <ActiveSoftphoneCall />}
      {incomingCall && <IncomingSoftphoneCallAlert />}
      {children}
    </SoftphoneContext.Provider>
  );
};

function useSoftphone() {
  return useContext(SoftphoneContext);
}

export { SoftphoneProvider, useSoftphone };
