import { Button } from "semantic-ui-react";
import { tones } from "../audio/dtmf/dtmfTones";

const style = {
  dialPad: {
    width: "245px",
  },
  dialPadButton: {
    width: "75px",
    height: "46px",
    padding: 0,
    margin: "5px 10px 5px 0px",
  },
};

function DialPad({ onClick }) {
  const handleClick = (value) => {
    new Audio(tones[value]).play();
    onClick(value);
  };

  return (
    <div style={style.dialPad}>
      <DialPadRow>
        <DialPadNumber value={1} onClick={handleClick} />
        <DialPadNumber value={2} onClick={handleClick} />
        <DialPadNumber value={3} onClick={handleClick} />
      </DialPadRow>

      <DialPadRow>
        <DialPadNumber value={4} onClick={handleClick} />
        <DialPadNumber value={5} onClick={handleClick} />
        <DialPadNumber value={6} onClick={handleClick} />
      </DialPadRow>

      <DialPadRow>
        <DialPadNumber value={7} onClick={handleClick} />
        <DialPadNumber value={8} onClick={handleClick} />
        <DialPadNumber value={9} onClick={handleClick} />
      </DialPadRow>

      <DialPadRow>
        <DialPadNumber color="grey" value={"*"} onClick={handleClick} />
        <DialPadNumber value={0} onClick={handleClick} />
        <DialPadNumber color="grey" value={"#"} onClick={handleClick} />
      </DialPadRow>
    </div>
  );
}

function DialPadRow({ children }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {children}
    </div>
  );
}

function DialPadNumber({ value, onClick }) {
  return (
    <div style={{ paddingLeft: "0", paddingRight: "0" }}>
      <Button
        style={style.dialPadButton}
        type="button"
        basic
        inverted
        size="big"
        onClick={() => onClick(value)}
      >
        {value}
      </Button>
    </div>
  );
}

export default DialPad;
