import { sdk } from "@gc/ipecs-web-sdk";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDevices } from "./DevicesContext";
import { usePhoneControl } from "./PhoneControlContext";
import { useSoftphone } from "./SoftphoneContext";

const CallsContext = React.createContext();
const CallsProvider = ({ phoneControlMode, children }) => {
  const { call } = sdk;
  const { audioInput, audioOutput, videoInput, handleInitDevices } =
    useDevices();
  const [vmInfo, setVmInfo] = useState();

  const softphone = useSoftphone();
  const phoneControl = usePhoneControl();

  const getVmLink = async () => {
    return await call.getVoiceMailLink();
  };

  const handleVmCount = useCallback(() => {
    call.getVoiceMailCount().then((res) => setVmInfo(res));
  }, [call]);

  useEffect(() => handleVmCount(), [handleVmCount]);

  // setup event listeners
  useEffect(() => {
    call.onVMCountReceived((event) => {
      setVmInfo(event);
    });
  }, [call]);

  const hasActiveCall = () => {
    const activeCall = phoneControlMode
      ? phoneControl.activeCall
      : softphone.activeCall;
    return activeCall ? true : false;
  };

  const handleMakeCall = async (destinationNumber, mediaType, delay = 0) => {
    if (phoneControlMode) {
      phoneControl.handleMakeCall(destinationNumber, mediaType, delay);
    } else {
      softphone.handleMakeCall(destinationNumber, mediaType, delay);
    }
  };

  const handleUnparkCall = async (parkingLocation) => {
    if (phoneControlMode) {
      toast("Call parking not available in phone control mode", {
        type: "error",
      });
    } else {
      // check the audio devices selected are actually available
      await handleInitDevices();
      const _devices = await call.getMediaDevicesInfo();
      const _audioDeviceIds = _devices
        .filter((x) => x.kind === "audiooutput")
        .map((x) => x.deviceId);
      const _videoDeviceIds = _devices
        .filter((x) => x.kind === "videoinput")
        .map((x) => x.deviceId);

      if (audioInput) {
        await call.changeAudioInputRequest(
          _audioDeviceIds.includes(audioInput) ? audioInput : undefined,
        );
      }

      if (audioOutput) {
        await call.changeAudioOutputRequest(
          _audioDeviceIds.includes(audioOutput) ? audioOutput : undefined,
        );
      }

      if (videoInput) {
        await call.changeVideoDeviceRequest(
          _videoDeviceIds.includes(videoInput) ? videoInput : undefined,
        );
      }

      softphone.handleUnparkCall(parkingLocation);
    }
  };

  const defaultContext = {
    hasActiveCall,
    handleMakeCall,
    handleUnparkCall,
    vmInfo,
    getVmLink,
  };

  return (
    <CallsContext.Provider value={defaultContext}>
      {children}
    </CallsContext.Provider>
  );
};

function useCalls() {
  return useContext(CallsContext);
}

export { CallsProvider, useCalls };
