import { useInfiniteQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "semantic-ui-react";
import { getWhatsappThreads } from "../api/whatsapp";
import SideScrollerLayout, {
  SideScrollerContent,
  SideScrollerSidebar,
} from "./SideScrollerLayout";
import { MessageListItem } from "./messaging/MessageListItem";

function WhatsappContainer({ children }) {
  const { id } = useParams();

  const threadsQuery = useInfiniteQuery({
    queryKey: ["whatsapp-threads"],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await getWhatsappThreads({
        page: pageParam,
      });
      return res;
    },
    getNextPageParam: (lastPage) =>
      lastPage.data.meta.current_page < lastPage.data.meta.last_page
        ? lastPage.data.meta.current_page + 1
        : undefined,
    keepPreviousData: true,
    cacheTime: 60 * 60 * 1000,
  });

  if (threadsQuery.isError) {
    toast("Failed to fetch Whatsapp conversations", { type: "error" });
    return null;
  }

  return (
    <SideScrollerLayout>
      <SideScrollerSidebar>
        {!threadsQuery.isLoading &&
          threadsQuery &&
          threadsQuery?.data?.pages.map((page) => {
            return (
              <React.Fragment
                key={`threads-page-${page.data.meta.current_page}`}
              >
                {page.data.data.map((x) => {
                  return (
                    <MessageListItem
                      key={x.sqid}
                      id={id}
                      chatId={x.sqid}
                      chatUrl={`/whatsapp/${x.sqid}`}
                      color="violet"
                      hasUnread={x.has_unread}
                      displayName={`Chat with ${x.provider_id}`}
                      message={x.latest_message}
                      timestamp={x.updated_at}
                    />
                  );
                })}
              </React.Fragment>
            );
          })}

        {threadsQuery.hasNextPage && (
          <div
            style={{ width: "100%", textAlign: "center", padding: "15px 0px" }}
          >
            <Button
              color="violet"
              onClick={() => {
                threadsQuery.fetchNextPage();
              }}
              disabled={threadsQuery.isFetchingNextPage}
              loading={threadsQuery.isFetchingNextPage}
            >
              Load more
            </Button>
          </div>
        )}
      </SideScrollerSidebar>
      <SideScrollerContent>{children}</SideScrollerContent>
    </SideScrollerLayout>
  );
}

export default WhatsappContainer;
