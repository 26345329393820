import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { GridColumn, Header, Grid } from "semantic-ui-react";
import ContactDisplayName from "../ContactDisplayName";
import { useAuth } from "../../contexts/AuthContext";

const style = {
  container: {
    position: "absolute",
    top: "40vh",
    width: "100vw",
    maxHeight: "calc(100vh - 100px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    zIndex: 4,
    transform: "translateY(-50%)",
  },
};

function ActiveAudioCall({
  minimized,
  activeCall,
  activeCallParties,
  callAnimationRef,
  transferNumber,
  handleCallAnswered,
  setCallDuration,
}) {
  const [timer, setTimer] = useState(0);
  const { apiUser } = useAuth();
  const onHold = activeCall?.state === "hold";

  const getColumns = () => {
    return Math.min(Math.max(activeCallParties.length, 1), 5);
  };

  useEffect(() => {
    handleCallAnswered();
    const timerInterval = setInterval(
      () => setTimer((oldTimer) => oldTimer + 1),
      1000,
    );
    return () => clearInterval(timerInterval);
  }, [handleCallAnswered]);

  useEffect(() => {
    setCallDuration(timer);
  }, [timer, setCallDuration]);

  return (
    <div style={style.container}>
      {!minimized && (
        <Grid padded={true} centered stackable columns={getColumns()}>
          {activeCallParties.map((callParty, index) => (
            <GridColumn
              key={`call-party-${index}`}
              style={{
                maxWidth: "40vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxHeight: "60vh",
              }}
            >
              {callParty?.avatar === undefined ||
              callParty?.avatar.substring(0, 7) === "initial" ? (
                <>
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: callAnimationRef.current,
                    }}
                    height="100%"
                    width="100%"
                  />
                </>
              ) : (
                <img
                  src={callParty?.avatar}
                  alt="Avatar"
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                    borderRadius: "12px",
                  }}
                />
              )}
              <Header>
                {callParty.callNumber ? (
                  <ContactDisplayName
                    name={callParty?.name}
                    number={callParty?.callNumber}
                    autoOpenCrmLink={apiUser.auto_open_crm_record}
                  />
                ) : (
                  <>{callParty.name}</>
                )}
              </Header>
            </GridColumn>
          ))}
        </Grid>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TextAnimation>
          {onHold ? "Call on hold" : "Call in Progress"}
          {transferNumber && transferNumber.length > 0 && (
            <p>Transferring to {transferNumber}</p>
          )}
        </TextAnimation>

        <p>
          {Math.floor(timer / 60).toLocaleString("en-GB", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
          :
          {(timer % 60).toLocaleString("en-GB", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
        </p>
      </div>
    </div>
  );
}

function TextAnimation({ children }) {
  return (
    <motion.p
      animate={{
        opacity: [1, 1, 0.5, 1, 1],
      }}
      transition={{
        duration: 5,
        ease: "easeInOut",
        times: [0, 0.4, 0.6, 0.8, 1],
        repeat: Infinity,
      }}
    >
      {children}
    </motion.p>
  );
}

export default ActiveAudioCall;
