import { useQuery } from "@tanstack/react-query";
import { lookupByCallerid } from "../api/contacts";
import { useAuth } from "../contexts/AuthContext";
import { convertToE164 } from "../helpers/convertToE164";

function useContactLookup(number) {
  const { apiUser } = useAuth();

  const searchQuery = useQuery({
    queryKey: ["callerid-lookup", [number]],
    queryFn: () => {
      const e164Number = convertToE164(number, apiUser?.phone_locality);
      return lookupByCallerid(e164Number);
    },
    enabled: number && number.length >= 10 ? true : false,
    keepPreviousData: true,
    cacheTime: 3600000, // one hour
    staleTime: 3600000, // one hour
    retry: false,
  });

  const contact = searchQuery.data?.data?.data ?? null;

  return { contact };
}

export default useContactLookup;
