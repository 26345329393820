import axios from "axios";
import config from "../config";

export function postCallStarted(body) {
  return axios.post(`${config.api_url}/call-events/started`, body);
}

export function postCallEnded(body) {
  return axios.post(`${config.api_url}/call-events/ended`, body);
}

export function postCallAnswered(body) {
  return axios.post(`${config.api_url}/call-events/answered`, body);
}
