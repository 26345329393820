import React from "react";

const style = {
  fullScreen: {
    top: 0,
    left: 0,
    position: "fixed",
    width: "100vw",
    height: "100vh",
    backgroundColor: "#000",
    color: "#fff",
    zIndex: 99999,
  },
  minimized: {
    bottom: 0,
    left: 0,
    position: "fixed",
    width: "100px",
    height: "220px",
    backgroundColor: "#000",
    color: "#fff",
    zIndex: 99999,
    transition: "all 150ms ease-in-out",
    borderTop: "solid 2px #505050",
    borderRight: "solid 2px #505050",
    borderTopRightRadius: "10px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
};

function ActiveCallContainer({ minimized, children }) {
  return (
    <div style={minimized ? style.minimized : style.fullScreen}>
      <div style={style.container}>{children}</div>
    </div>
  );
}

export default ActiveCallContainer;
