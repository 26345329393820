import axios from "axios";
import config from "../config";

export function searchGifs(query) {
  return axios.get(
    `https://tenor.googleapis.com/v2/search?q=${query}&key=${config.tenor_api_key}&client_key=gc_connect&limit=12&contentfilter=high`,
    { withCredentials: false }
  );
}

export function featuredGifs() {
  return axios.get(
    `https://tenor.googleapis.com/v2/featured?key=${config.tenor_api_key}&client_key=gc_connect&limit=12&contentfilter=high`,
    { withCredentials: false }
  );
}
