import axios from "axios";
import config from "../config";

export function getClockifyOverview() {
  return axios.get(`${config.api_url}/clockify`);
}

export function stopActiveTimer() {
  return axios.post(`${config.api_url}/clockify/stop-active-timer`);
}
