import { FileMessage, ImageMessage, TextMessage } from "./MessageTypes";

function MessageContent({
  message,
  messageType,
  isDeleted,
  downloadUrl,
  caption,
  fileName,
  byMe,
}) {
  switch (messageType) {
    case "m.room.message.text":
    case "engage":
    case "text":
      return isDeleted ? (
        <em>Deleted</em>
      ) : message.startsWith("[gif:") ? (
        <ImageMessage message={message} isGif={true} />
      ) : (
        <TextMessage message={message} />
      );
    case "m.room.message.img":
    case "image":
      return isDeleted ? (
        <em>Deleted</em>
      ) : (
        <ImageMessage
          message={message}
          downloadUrl={downloadUrl}
          caption={caption}
        />
      );
    case "m.room.message.file":
    case "file":
      return isDeleted ? (
        <em>Deleted</em>
      ) : (
        <FileMessage
          message={message}
          downloadUrl={downloadUrl}
          fileName={fileName}
          caption={caption}
          byMe={byMe}
        />
      );
    default:
      return null;
  }
}

export default MessageContent;
