import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Container, Header, Segment, Step } from "semantic-ui-react";
import { getByBillingId as getCustomerByBillingId } from "../api/customers";
import {
  createOrderForm,
  submitOrderForm,
  updateOrderForm,
} from "../api/order-forms";
import Confirm from "../components/setupForm/Confirm";
import ConnectDetails from "../components/setupForm/ConnectDetails";
import CustomerDetails from "../components/setupForm/CustomerDetails";
import SidekickDetails from "../components/setupForm/SidekickDetails";
import WhatsappDetails from "../components/setupForm/WhatsappDetails";
import logo from "../logos/icon_white.svg";
import { toast } from "react-toastify";

function Setup() {
  const { billingPartnerId } = useParams();

  const initialCustomerDetails = {
    id: "",
    licenses: [],
    config: {
      connect_ui: false,
      connect_api: false,
      whatsapp: false,
      sidekick: false,
    },
  };

  const initialFormState = {
    id: "",
    customer: {
      billing_partner_id: billingPartnerId,
      billing_id: "",
      reseller_name: "",
      customer_name: "",
    },
    connect: {
      required: false,
      ui_required: false,
      api_required: false,
      api_call_recordings_required: false,
      tenant_prefix: "",
      ipecs_server_url: "",
      ipecs_api_key: "",
      ipecs_api_key_secret: "",
      ipecs_manager_url: "",
      ipecs_manager_username: "",
      ipecs_manager_password: "",
      quantity: 0,
    },
    whatsapp: {
      required: false,
      quantity: 0,
    },
    sidekick: {
      required: false,
      quantity: 0,
      admin_email: "",
      admin_extension: "",
    },
  };

  const [formStep, setFormStep] = useState(0);
  const [fetchingAccount, setFetchingAccount] = useState(false);
  const [newCustomer, setNewCustomer] = useState(false);
  const [customerDetails, setCustomerDetails] = useState(
    initialCustomerDetails,
  );
  const [formState, setFormState] = useState(initialFormState);

  useEffect(() => {
    console.log(customerDetails);
    console.log(formState);
  }, [customerDetails, formState]);

  const createOrderMutation = useMutation({
    mutationFn: () => {
      return createOrderForm(formState);
    },
    onSuccess: (res) => {
      setFormState(res.data.data);
      setFormStep((formStep) => formStep + 1);
    },
  });

  const updateOrderMutation = useMutation({
    mutationFn: () => updateOrderForm(formState.id, formState),
    onSuccess: (res) => {
      setFormState(res.data.data);
      setFormStep((formStep) => formStep + 1);
    },
    onError: (error) => {
      toast(error.response.data.message, { type: "error" });
    },
  });

  const submitOrderMutation = useMutation({
    mutationFn: () => submitOrderForm(formState.id),
    onSuccess: () => {
      setFormStep(5);
    },
  });

  const handleChangeCustomer = (e) => {
    const { name, value } = e.target;

    setFormState((formState) => ({
      ...formState,
      customer: { ...formState.customer, [name]: value },
    }));
  };

  const handleChangeConnect = (e, data) => {
    const { name, value } = data;

    setFormState((formState) => ({
      ...formState,
      connect: { ...formState.connect, [name]: value },
    }));
  };

  const handleChangeWhatsapp = (e, data) => {
    const { name, value } = data;
    setFormState((formState) => ({
      ...formState,
      whatsapp: { ...formState.whatsapp, [name]: value },
    }));
  };

  const handleChangeSidekick = (e, data) => {
    const { name, value } = data;
    setFormState((formState) => ({
      ...formState,
      sidekick: { ...formState.sidekick, [name]: value },
    }));
  };

  const handleCheckBillingId = async (e) => {
    try {
      setFetchingAccount(true);
      const res = await getCustomerByBillingId(formState.customer.billing_id);

      setCustomerDetails(res.data?.data);

      createOrderMutation.mutate();
    } catch (e) {
      setCustomerDetails(initialCustomerDetails);
      setNewCustomer(true);
    } finally {
      setFetchingAccount(false);
    }
  };

  const handleCreateCustomer = (e) => {
    createOrderMutation.mutate();
  };

  const handleStartAgain = (e) => {
    setNewCustomer(false);
    setFormState(initialFormState);
    setCustomerDetails(initialCustomerDetails);
    setFormStep(0);
  };

  const handleNext = () => {
    updateOrderMutation.mutate();
  };

  const handleBack = () => {
    setFormStep((formStep) => formStep - 1);
  };

  const handleConfirm = () => {
    submitOrderMutation.mutate();
  };

  return (
    <Container style={{ paddingTop: "100px" }}>
      <Header as="h1">
        <img src={logo} width={100} alt="Logo" />
        Licence Provisioning
      </Header>

      <Step.Group inverted attached="top" widths={5}>
        <Step inverted active={formStep === 0}>
          Customer details
        </Step>
        <Step active={formStep === 1}>Connect</Step>
        <Step active={formStep === 2}>Whatsapp</Step>
        <Step active={formStep === 3}>Sidekick</Step>
        <Step active={formStep === 4}>Confirm</Step>
      </Step.Group>

      <Segment attached>
        {formStep === 0 && (
          <CustomerDetails
            submitting={createOrderMutation.isLoading}
            newCustomer={newCustomer}
            customer={formState.customer}
            searching={fetchingAccount}
            onChange={handleChangeCustomer}
            onSubmit={handleCreateCustomer}
            onSearch={handleCheckBillingId}
            onStartAgain={handleStartAgain}
          />
        )}

        {formStep === 1 && (
          <ConnectDetails
            submitting={updateOrderMutation.isLoading}
            uiConfigured={customerDetails.config.connect_ui}
            apiConfigured={customerDetails.config.connect_api}
            connect={formState.connect}
            onChange={handleChangeConnect}
            onSubmit={handleNext}
            onClickBack={handleBack}
          />
        )}

        {formStep === 2 && (
          <WhatsappDetails
            submitting={updateOrderMutation.isLoading}
            configured={customerDetails.config.whatsapp}
            whatsapp={formState.whatsapp}
            onChange={handleChangeWhatsapp}
            onSubmit={handleNext}
            onClickBack={handleBack}
          />
        )}

        {formStep === 3 && (
          <SidekickDetails
            submitting={updateOrderMutation.isLoading}
            configured={customerDetails.config.sidekick}
            sidekick={formState.sidekick}
            onChange={handleChangeSidekick}
            onSubmit={handleNext}
            onClickBack={handleBack}
          />
        )}

        {formStep === 4 && (
          <Confirm
            submitting={submitOrderMutation.isLoading}
            formState={formState}
            onSubmit={handleConfirm}
            onClickBack={handleBack}
          />
        )}

        {formStep === 5 && (
          <>
            <Header>Your order has been placed</Header>
            <p>
              You will receive an email containing a link to approve this order.
            </p>
            <p>
              You will need to click this link in order to process this order.
            </p>
            <p>Order reference: {formState.id}</p>
            <Button onClick={handleStartAgain} primary>
              Place another order
            </Button>
          </>
        )}
      </Segment>
    </Container>
  );
}

export default Setup;
