import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Container,
  Divider,
  Header,
  Icon,
  Item,
  Label,
  Segment,
} from "semantic-ui-react";
import { getClockifyOverview, stopActiveTimer } from "../api/clockify";
import PageLoader from "../components/PageLoader";

const calcSeconds = (startTimestamp) => {
  const now = Math.round(new Date().getTime() / 1000);
  const seconds = now - startTimestamp;
  const str = new Date(seconds * 1000).toISOString().substr(11, 8);
  return str;
};

function Clockify() {
  const [loading, setLoading] = useState(true);
  const [overview, setOverview] = useState();
  const [stopping, setStopping] = useState(false);
  const [activeSeconds, setActiveSeconds] = useState();

  const handleStopTimer = () => {
    setStopping(true);
    stopActiveTimer()
      .then((res) => setOverview(res.data.data))
      .catch(() => toast("Failed to stop timer", { type: "error" }))
      .finally(() => setStopping(false));
  };

  useEffect(() => {
    getClockifyOverview()
      .then((res) => setOverview(res.data.data))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const setSeconds = () => {
      if (overview?.active) {
        setActiveSeconds(calcSeconds(overview.active.start_timestamp));
      }
    };

    const interval = setInterval(setSeconds, 1000);
    return () => clearInterval(interval);
  }, [overview?.active]);

  if (loading) return <PageLoader>Clockify loading...</PageLoader>;

  return (
    <Container>
      <Segment basic>
        <Header size="huge">Time Tracking</Header>

        {overview.active && (
          <Item.Group>
            <TimeEntry entry={overview.active}>
              <Button as="div" labelPosition="right">
                <Button
                  color="red"
                  onClick={handleStopTimer}
                  loading={stopping}
                  disabled={stopping}
                >
                  <Icon name="clock" />
                  Stop Timer
                </Button>
                <Label
                  style={{ minWidth: "80px" }}
                  color="grey"
                  pointing="left"
                >
                  {activeSeconds ?? "--:--:--"}
                </Label>
              </Button>
            </TimeEntry>
          </Item.Group>
        )}
        <Header size="large">Previous Timers</Header>
        {overview.entries.length === 0 && <p>No timers found</p>}
        {overview.entries.map((x, i) => (
          <TimeEntry key={`entry-${i}`} entry={x} />
        ))}
        <Item.Group></Item.Group>
      </Segment>
    </Container>
  );
}

function TimeEntry({ entry, children }) {
  return (
    <>
      <Item>
        <Item.Content>
          <Item.Header>{entry.description}</Item.Header>
          <Item.Description>{entry.project}</Item.Description>
          <Item.Meta>
            {entry.start}
            {entry.end && (
              <>
                {" "}
                - {entry.end} - {entry.duration}
              </>
            )}
          </Item.Meta>
          <Item.Meta>
            {entry.tags.map((x) => (
              <Label
                key={x}
                color="teal"
                size="mini"
                tag
                style={{ marginRight: "14px" }}
              >
                {x}
              </Label>
            ))}
          </Item.Meta>
          <Item.Extra>{children}</Item.Extra>
        </Item.Content>
      </Item>
      <Divider />
    </>
  );
}

export default Clockify;
