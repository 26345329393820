import { Button, Form, FormField, Input, Select } from "semantic-ui-react";
import YesNoToggle from "./YesNoToggle";

function ConnectDetails({
  uiConfigured,
  apiConfigured,
  connect,
  onChange,
  onSubmit,
  onClickBack,
  submitting,
}) {
  return (
    <>
      <Form onSubmit={onSubmit}>
        <Form.Field>
          <label>Is this a CONNECT order?</label>
          <YesNoToggle value={connect.required} onChange={onChange} />
        </Form.Field>
        {connect.required && (
          <>
            <FormField>
              <label>Quantity of Connect licenses</label>
              <Input
                type="number"
                name="quantity"
                onChange={onChange}
                value={connect.quantity}
              />
            </FormField>

            {!uiConfigured && (
              <>
                <Form.Field>
                  <label>Will they be using the Connect User Interface?</label>
                  <Select
                    name="ui_required"
                    value={connect.ui_required}
                    onChange={onChange}
                    options={[
                      { key: "yes", value: true, text: "Yes" },
                      { key: "no", value: false, text: "No" },
                    ]}
                  />
                </Form.Field>

                <Form.Field>
                  <label>Please enter tenant prefix</label>
                  <Input
                    name="tenant_prefix"
                    onChange={onChange}
                    value={connect.tenant_prefix}
                    fluid
                  />
                </Form.Field>

                {connect.ui_required && (
                  <>
                    <FormField>
                      <label>iPECS One Server URL</label>
                      <Input
                        name="ipecs_server_url"
                        onChange={onChange}
                        value={connect.ipecs_server_url}
                      />
                    </FormField>
                    <FormField>
                      <label>iPECS One API Key</label>
                      <Input
                        name="ipecs_api_key"
                        onChange={onChange}
                        value={connect.ipecs_api_key}
                      />
                    </FormField>
                    <FormField>
                      <label>IPECS One API Key Secret</label>
                      <Input
                        type="password"
                        autocomplete="new-password"
                        name="ipecs_api_key_secret"
                        onChange={onChange}
                        value={connect.ipecs_api_key_secret}
                      />
                    </FormField>
                  </>
                )}
              </>
            )}

            {!apiConfigured && (
              <Form.Field>
                <label>Will they be using the Connect API?</label>
                <Select
                  name="api_required"
                  value={connect.api_required}
                  onChange={onChange}
                  options={[
                    { key: "yes", value: true, text: "Yes" },
                    { key: "no", value: false, text: "No" },
                  ]}
                />
              </Form.Field>
            )}
          </>
        )}

        <Button.Group>
          <Button onClick={onClickBack}>Back</Button>
          <Button.Or />
          <Button loading={submitting} primary>
            Next
          </Button>
        </Button.Group>
      </Form>
    </>
  );
}

export default ConnectDetails;
