import { useQuery } from "@tanstack/react-query";
import { Card, Container, Header, Loader, Segment } from "semantic-ui-react";
import { getInsights } from "../api/insights";
import StatCard from "../components/StatCard";

export const colours = {
  teal: "#58b9b7",
  violet: "#98a0d9",
  purple: "#ca98d9",
  blue: "#98b6d9",
  green: "#5cb958",
  red: "#e85858",
};

function Insights() {
  const insightsQuery = useQuery(
    ["insights"],
    () => {
      return getInsights();
    },
    {
      keepPreviousData: true,
      cacheTime: 3600000, // one hour
      staleTime: 600000, // 10 mins
    }
  );

  return (
    <Container>
      <Segment basic>
        <h1>Insights</h1>
        <Header>Calls</Header>
        <Card.Group>
          <StatCard
            label="Total calls"
            value={insightsQuery.data?.data?.data.calls.count}
            colour={colours.purple}
            loading={insightsQuery.isFetching}
          />
          <StatCard
            label="Average Talk Time"
            value={insightsQuery.data?.data?.data.calls.average_duration}
            colour={colours.purple}
            loading={insightsQuery.isFetching}
          />
          <StatCard
            label="Total Talk Time"
            value={insightsQuery.data?.data?.data.calls.total_duration}
            colour={colours.purple}
            loading={insightsQuery.isFetching}
          />
          <StatCard
            label="Recent Outgoing Calls"
            value={insightsQuery.data?.data?.data.calls.outgoing.count}
            colour={colours.violet}
            loading={insightsQuery.isFetching}
          />
          <StatCard
            label=""
            value={insightsQuery.data?.data?.data.calls.outgoing.split_percent}
            colour={colours.violet}
            loading={insightsQuery.isFetching}
          />
        </Card.Group>
        <Card.Group>
          <StatCard
            label="Recent Incoming Calls"
            value={insightsQuery.data?.data?.data.calls.incoming.count}
            colour={colours.teal}
            loading={insightsQuery.isFetching}
          />
          <StatCard
            label=" "
            value={insightsQuery.data?.data?.data.calls.incoming.split_percent}
            colour={colours.teal}
            loading={insightsQuery.isFetching}
          />
        </Card.Group>
        <Header>Calendar</Header>
        <Card.Group>
          <StatCard
            label="Events This Week"
            value={insightsQuery.data?.data?.data.events.count}
            colour={colours.purple}
            loading={insightsQuery.isFetching}
          />
          <StatCard
            label="Time in Events"
            value={insightsQuery.data?.data?.data.events.busy_time}
            colour={colours.purple}
            loading={insightsQuery.isFetching}
          />
          <StatCard
            label="Time in Events"
            value={`${insightsQuery.data?.data?.data.events.busy_percent}%`}
            colour={colours.purple}
            loading={insightsQuery.isFetching}
          />
        </Card.Group>

        <div style={{ marginTop: "12px" }}>
          <small>
            Looking at busy events Monday to Friday, between 9am and 5pm.
          </small>
        </div>

        {insightsQuery.data?.data?.data.hasOwnProperty("tasks") && (
          <>
            <Header>Tasks</Header>
            <Card.Group>
              <StatCard
                label="Overdue"
                value={insightsQuery.data?.data?.data.tasks.overdue_count}
                colour={colours.blue}
                loading={insightsQuery.isFetching}
              />
              <StatCard
                label="Due Today"
                value={insightsQuery.data?.data?.data.tasks.today_count}
                colour={colours.blue}
                loading={insightsQuery.isFetching}
              />
              <StatCard
                label="Unscheduled"
                value={insightsQuery.data?.data?.data.tasks.unscheduled_count}
                colour={colours.blue}
                loading={insightsQuery.isFetching}
              />
            </Card.Group>
          </>
        )}
      </Segment>

      <Segment basic>
        {!insightsQuery.isFetching && (
          <small>
            Last refreshed at {insightsQuery.data?.data?.data.refreshed_at}
          </small>
        )}
      </Segment>

      {insightsQuery.isFetching && <Loader active inline="centered" />}
    </Container>
  );
}

export default Insights;
