import { Image, Modal } from "semantic-ui-react";

const ImageModal = ({
  imageUrl,
  setImageModalOpen,
  imageModalOpen,
  caption,
}) => {
  return (
    <Modal
      basic
      closeIcon
      onClose={() => setImageModalOpen(false)}
      open={imageModalOpen}
      size="small"
    >
      <Modal.Content>
        <Image src={imageUrl} size="huge" />
        {caption && <div style={{ marginTop: "10px" }}>{caption}</div>}
      </Modal.Content>
    </Modal>
  );
};

export default ImageModal;
