import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Header,
  Icon,
  Image,
  Modal,
  ModalActions,
  ModalContent,
} from "semantic-ui-react";
import {
  getCallRecordingTranscriptions,
  summariseExistingTranscription,
} from "../../api/transcriptions";
import sidekickLogoWhite from "../../images/sidekick-logo-white-75x79.png";
import { style } from "../../screens/Sidekick";
import PageLoader from "../PageLoader";
import { getSentimentIcon } from "./CallTranscriptionRow";

const copyRichText = (element) => {
  navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
    if (result.state === "granted" || result.state === "prompt") {
      const clipboardItemInput = new ClipboardItem({
        "text/plain": new Blob([element.innerText], {
          type: "text/plain",
        }),
        "text/html": new Blob([element.outerHTML], { type: "text/html" }),
      });

      navigator.clipboard.write([clipboardItemInput]).then(
        () => {
          toast("Copied to clipboard", { type: "success" });
        },
        () => {
          toast("Unable to copy content", { type: "error" });
        },
      );
    } else {
      toast(
        "Unable to copy content. Are you using an up-to-date version of Chrome?",
        { type: "error" },
      );
    }
  });
};

const CallInfoModal = ({ infoModal, setInfoModal }) => {
  const summaryQuery = useQuery(
    ["call-summary", "hydrate", infoModal.id],
    () => {
      return getCallRecordingTranscriptions(infoModal.id, { hydrate: "1" });
    },
    {
      keepPreviousData: false,
      staleTime: 60000,
      // Disable unless we're passing a call ID, otherwise it'll make an unneccessary API call on mount
      enabled: !!infoModal.id,
      refetchInterval: (data) => {
        let interval = false;
        if (["SUMMARISING"].includes(data?.data?.data?.status?.value)) {
          interval = 30 * 1000;
        }
        return interval;
      },
    },
  );

  if (summaryQuery.isError) {
    toast("Unable to get call details", { type: "error" });
    setInfoModal({ open: false, id: "", tab: "summary" });
  }

  return (
    <InfoModalContent
      infoModal={infoModal}
      setInfoModal={setInfoModal}
      isLoading={summaryQuery.isLoading}
      data={summaryQuery?.data?.data?.data}
    >
      {infoModal.tab === "summary" ? (
        <Summary data={summaryQuery?.data?.data?.data} />
      ) : (
        <Transcription data={summaryQuery?.data?.data?.data} />
      )}
    </InfoModalContent>
  );
};

const InfoModalContent = ({
  children,
  data,
  infoModal,
  setInfoModal,
  isLoading,
}) => {
  const queryClient = useQueryClient();
  const [summaryIsLoading, setSummaryIsLoading] = useState(
    data?.status.value === "SUMMARISING",
  );

  const handleError = (error) => {
    toast(
      error.response?.data?.message ?? "There was an error with your request",
      {
        type: "error",
        autoClose: 10000,
        toastId: "sidekick-limit-error",
      },
    );
  };

  return (
    <Modal
      centered={false}
      closeIcon
      onClose={() => setInfoModal({ open: false, id: "", tab: "summary" })}
      open={infoModal.open}
    >
      <Header>
        <ButtonGroup>
          <Button
            style={
              infoModal.tab === "transcription"
                ? style.gradientButton
                : style.gradientButtonInactive
            }
            onClick={() => setInfoModal({ ...infoModal, tab: "transcription" })}
          >
            Transcription
          </Button>
          {data?.has_summary && (
            <Button
              style={
                infoModal.tab === "summary"
                  ? {
                      ...style.purpleGradientButton,
                      ...style.modalSummaryButton,
                    }
                  : {
                      ...style.gradientButtonInactive,
                      ...style.modalSummaryButton,
                    }
              }
              active={infoModal.tab === "summary"}
              onClick={() => setInfoModal({ ...infoModal, tab: "summary" })}
            >
              <>
                <Image
                  src={sidekickLogoWhite}
                  alt="Sidekick logo"
                  style={{ width: "20px" }}
                />
                AI
              </>
            </Button>
          )}
        </ButtonGroup>
      </Header>
      <ModalContent>
        {isLoading ? <PageLoader>Loading...</PageLoader> : children}
      </ModalContent>
      <ModalActions>
        <div style={{ display: "flex", gap: "15px", justifyContent: "end" }}>
          {infoModal.tab === "transcription" &&
            !data?.has_summary &&
            data?.can_summarise && (
              <Button
                style={{
                  ...style.purpleGradientButton,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "8px 21px",
                  width: "110px",
                  justifyContent: "space-around",
                }}
                onClick={async () => {
                  setSummaryIsLoading(true);
                  try {
                    await summariseExistingTranscription(data.sqid);
                    queryClient.invalidateQueries({
                      queryKey: ["call-summary", "hydrate", data.sqid],
                    });
                    queryClient.invalidateQueries({
                      queryKey: ["call-transcriptions"],
                    });
                    queryClient.invalidateQueries({
                      queryKey: ["call-recordings"],
                    });
                  } catch (e) {
                    setSummaryIsLoading(false);
                    handleError(e);
                  }
                }}
                loading={summaryIsLoading}
              >
                {!summaryIsLoading && (
                  <>
                    <Image
                      src={sidekickLogoWhite}
                      alt="Sidekick logo"
                      style={{ width: "20px" }}
                    />
                    AI
                  </>
                )}
              </Button>
            )}
          <CopyButton />
        </div>
      </ModalActions>
    </Modal>
  );
};

const Summary = ({ data }) => {
  const sentimentIcon = getSentimentIcon(data?.sentiment?.rating);
  return (
    <Container id="content">
      {data?.sentiment && (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Icon
              name={sentimentIcon.name}
              size="big"
              style={{ color: sentimentIcon.color }}
            />
            <p>{data?.sentiment?.summary}</p>
          </div>
        </>
      )}
      <Header as="h2">SUMMARY</Header>
      <div>
        {data?.can_summarise ? (
          <>
            <p>{data?.summary}</p>
          </>
        ) : (
          data?.summary
        )}
      </div>
      {data?.meeting_notes && (
        <>
          <Header as="h2">MEETING NOTES</Header>
          <div>
            {!data.meeting_notes
              ? "No meeting notes available."
              : data?.meeting_notes?.map((i) => (
                  <React.Fragment key={i.header}>
                    <Header as="h3">{i.header}</Header>
                    <ul>
                      {i.content.map((note) => (
                        <li key={note}>{note}</li>
                      ))}
                    </ul>
                  </React.Fragment>
                ))}
          </div>
        </>
      )}
    </Container>
  );
};

const Transcription = ({ data }) => {
  return (
    <Container id="content">
      <Header as="h2">TRANSCRIPTION</Header>
      {(data?.transcription?.conversation ?? []).map((x, i) => (
        <Card
          fluid
          key={i}
          header={x.participant}
          description={x.content}
          style={{
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: x.color ?? "rgba(0,0,0,0)",
          }}
        />
      ))}
    </Container>
  );
};

const CopyButton = () => {
  return (
    <Button
      icon
      labelPosition="left"
      color="teal"
      onClick={() => {
        const el = document.getElementById("content");
        copyRichText(el);
      }}
    >
      <Icon name="copy" />
      Copy
    </Button>
  );
};

export default CallInfoModal;
