import { useEffect, useState } from "react";
import { Icon, Label } from "semantic-ui-react";

const getColor = (status) => {
  if (!status) return "green";

  switch (status) {
    case "dnd":
    case "busy":
    case "in_a_call":
      return "red";
    case "offline":
    case "unknown":
      return "grey";
    default:
      return "green";
  }
};

function PresenceIndicatorDot({ presence, ...otherProps }) {
  const [color, setColor] = useState();

  useEffect(() => {
    setColor(getColor(presence));
  }, [presence]);

  if (presence === "dnd") {
    return (
      <Icon
        name="dont"
        color={color}
        circular
        inverted
        size="tiny"
        {...otherProps}
      />
    );
  }

  return <Label color={color} size="small" circular empty {...otherProps} />;
}

export default PresenceIndicatorDot;
