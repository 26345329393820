import { addDays, format, formatISO, startOfWeek } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { Icon } from "semantic-ui-react";
import layout from "../layout";

function CalendarWeekView({ calendarEvents }) {
  const [dates, setDates] = useState([]);
  const [minutesGoneToday, setMinutesGoneToday] = useState(0);

  const startRef = useRef();

  const calcMinutesGoneToday = () => {
    const now = new Date();
    const hour = parseInt(format(now, "H"));
    const minutes = parseInt(format(now, "m"));
    setMinutesGoneToday(hour * 60 + 60 + minutes);
  };

  useEffect(() => {
    const _dates = [];
    const start = startOfWeek(new Date(), { weekStartsOn: 1 });

    for (let i = 0; i < 5; i++) {
      let newDate = addDays(start, i);
      _dates.push({
        date: newDate,
        today: format(newDate, "yyyyLLdd") === format(new Date(), "yyyyLLdd"),
      });
    }

    setDates(_dates);
  }, []);

  useEffect(() => {
    startRef.current.scrollIntoView({ behavior: "auto" });
  }, []);

  useEffect(() => {
    calcMinutesGoneToday();

    const minutesGoneTodayInterval = setInterval(async () => {
      calcMinutesGoneToday();
    }, 60000);

    return () => clearInterval(minutesGoneTodayInterval);
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.grid}>
        <CalendarCol>
          <CalendarColHeader></CalendarColHeader>
          {Array.apply(null, { length: 24 }).map((_, i) => (
            <div key={i} ref={i === 6 ? startRef : undefined}>
              <CalendarColHour>{i}</CalendarColHour>
            </div>
          ))}
        </CalendarCol>

        <div style={styles.gridEvents}>
          {dates.map((date) => (
            <CalendarCol today={date.today} key={formatISO(date.date)}>
              <CalendarColHeader>
                {format(date.date, "dd EEEE")}
              </CalendarColHeader>
              <CalendarEvents>
                {calendarEvents
                  .filter(
                    (x) =>
                      format(x.start.toDate(), "d") === format(date.date, "d")
                  )
                  .map((x, i) => {
                    return <Event key={`${x.id}-${i}`} event={x} />;
                  })}
              </CalendarEvents>
            </CalendarCol>
          ))}

          {minutesGoneToday > 0 && (
            <div
              style={{
                ...styles.now,
                top: `${minutesGoneToday}px`,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function CalendarCol({ today, children }) {
  return (
    <div
      style={{
        ...styles.col,
        borderLeft: today ? "solid 2px rgba(113, 116, 166, 0.3)" : "none",
        borderRight: today ? "solid 2px rgba(113, 116, 166, 0.3)" : "none",
      }}
    >
      {children}
    </div>
  );
}

function CalendarColHeader({ children }) {
  return <div style={styles.header}>{children}</div>;
}

function CalendarColHour({ children }) {
  return <div style={styles.hour}>{children}</div>;
}

function CalendarEvents({ children }) {
  return <div style={styles.events}>{children}</div>;
}

function Event({ event }) {
  const [hover, setHover] = useState(false);

  const startHour = parseInt(format(event.start.toDate(), "H"));
  const startMinutes = parseInt(format(event.start.toDate(), "m"));

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => window.open(event.web_link)}
      style={{
        ...styles.event,
        top: `${(startHour + startMinutes / 60) * 60}px`,
        backgroundColor: hover ? "#7174a6" : "#8e92d0",
        opacity: event.is_all_day ? 0.5 : 1,
        height: `${Math.min(event.duration_minutes, 1424)}px`,
      }}
    >
      <Icon name={event.provider} /> {event.subject}
    </div>
  );
}

const styles = {
  container: {
    height: "100%",
    width: "100%",
  },
  grid: {
    flexGrow: "1",
    padding: "1px",
    display: "grid",
    gridTemplateColumns: "60px 1fr",
    gridGap: "1px",
    backgroundColor: "black",
    height: `calc(100vh - ${layout.topbarHeight})`,
    minWidth: "640px",
    overflowY: "scroll",
    overflowX: "scroll",
  },
  gridEvents: {
    position: "relative",
    flexGrow: "1",
    padding: "1px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    gridGap: "1px",
    backgroundColor: "black",
    minWidth: "640px",
  },
  col: {
    backgroundColor: "#1b1b27",
    paddingTop: "60px",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
  header: {
    top: `calc(${layout.topbarHeight})`,
    position: "fixed",
    backgroundColor: "#1b1b27",
    zIndex: 9,
    width: "100%",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  hour: {
    height: "60px",
  },
  events: {
    position: "relative",
  },
  event: {
    position: "absolute",
    width: "100%",
    borderRadius: "12px",
    padding: "4px 8px",
    minHeight: "28px",
    cursor: "pointer",
    color: "#000",
    border: "solid 2px #000",
    transition: "all 200ms ease-in-out",
    overflow: "hidden",
  },
  now: {
    borderBottom: "1px solid rgba(255, 0, 0, 0.5)",
    position: "absolute",
    left: 0,
    width: "100%",
  },
};

export default CalendarWeekView;
