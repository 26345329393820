import { Navigate } from "react-router-dom";
import ChatContainer from "../components/ChatContainer";
import { useChat } from "../contexts/ChatContext";

function Chat() {
  const { rooms } = useChat();

  if (rooms.length > 0) {
    return <Navigate to={`/chat/${rooms[0].id}`} />;
  }

  return <ChatContainer />;
}

export default Chat;
