import axios from "axios";
import config from "../config";

export function getWhatsNew() {
  return axios.get(`${config.api_url}/whats-new`);
}

export function setWhatsNewRead() {
  return axios.post(`${config.api_url}/whats-new`);
}
