import { useInfiniteQuery } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  Dropdown,
  Header,
  HeaderContent,
  Icon,
  Image,
  Loader,
  Menu,
  MenuItem,
  Segment,
} from "semantic-ui-react";
import ContactCard from "../components/ContactCard";
import PageLoader from "../components/PageLoader";
import SideScrollerLayout, {
  SideScrollerContent,
  SideScrollerSidebar,
} from "../components/SideScrollerLayout";

import googleLogo from "../images/contact-provider-google.png";
import hubspotLogo from "../images/contact-provider-hubspot.png";
import salesforceLogo from "../images/contact-provider-salesforce.png";
import ipecsoneLogo from "../images/contact-provider-ipecsone.png";
import microsoftLogo from "../images/contact-provider-microsoft.png";
import mondayLogo from "../images/contact-provider-monday.png";
import zohoLogo from "../images/contact-provider-zoho.png";
import zohoDeskLogo from "../images/contact-provider-zoho-desk.png";

import { searchContacts } from "../api/contacts";
import CallLogs from "../components/CallLogs";
import { useAuth } from "../contexts/AuthContext";
import { useTopbar } from "../contexts/TopbarContext";

const Contacts = () => {
  const providers = {
    ipecsone: {
      value: "ipecsone",
      label: "iPECS ONE",
      logo: ipecsoneLogo,
      checkIntegration: false,
      canFilter: true,
    },
    microsoft: {
      value: "microsoft",
      label: "Microsoft",
      logo: microsoftLogo,
      checkIntegration: true,
      canFilter: true,
    },
    google: {
      value: "google",
      label: "Google",
      logo: googleLogo,
      checkIntegration: true,
      canFilter: true,
    },
    hubspot: {
      value: "hubspot",
      label: "Hubspot",
      logo: hubspotLogo,
      checkIntegration: true,
      canFilter: true,
    },
    salesforce: {
      value: "salesforce",
      label: "Salesforce",
      logo: salesforceLogo,
      checkIntegration: true,
      canFilter: true,
    },
    monday: {
      value: "monday",
      label: "Monday",
      logo: mondayLogo,
      checkIntegration: false,
      canFilter: false,
    },
    zoho: {
      value: "zoho",
      label: "Zoho CRM",
      logo: zohoLogo,
      checkIntegration: true,
      canFilter: true,
    },
    zohoDesk: {
      value: "zoho-desk",
      label: "Zoho Desk",
      logo: zohoDeskLogo,
      checkIntegration: true,
      canFilter: true,
    },
  };
  const sortOptions = [
    { key: 1, text: "first name", value: "first_name" },
    { key: 2, text: "last name", value: "last_name" },
    { key: 3, text: "favourites", value: "favourite" },
  ];

  const sortDesc = ["favourite"];

  const [providerFilter, setProviderFilter] = useState(
    localStorage.getItem("contactProvider") ?? "",
  );
  const [sortCriteria, setSortCriteria] = useState(
    localStorage.getItem("contactSort") ?? "first_name",
  );
  const [favouritesToggle, setFavouritesToggle] = useState(
    localStorage.getItem("favouritesToggle") === "true" ? true : false,
  );

  const contactAreaRef = useRef();

  const { debouncedSearch, isSearching, searchedUsers } = useTopbar();

  const { hasIntegration } = useAuth();

  const contactsData = useInfiniteQuery({
    queryKey: ["contacts", providerFilter, sortCriteria, favouritesToggle],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await searchContacts({
        provider: providerFilter,
        sort_by: sortCriteria,
        sort_dir: sortDesc.includes(sortCriteria) ? "desc" : "asc",
        favourites: favouritesToggle ? "true" : "",
        page_size: 16,
        page: pageParam,
      });
      return res.data;
    },
    getNextPageParam: (lastPage, pages) =>
      lastPage.meta.next_page ? lastPage.meta.next_page : undefined,
    keepPreviousData: true,
    cacheTime: 3600000, // one hour
    staleTime: 600000, // 10 mins
  });

  const handleFavouritesToggle = () => {
    localStorage.setItem("favouritesToggle", !favouritesToggle);
    setFavouritesToggle(!favouritesToggle);
  };

  const handleProviderClick = (provider) => {
    setProviderFilter(provider === providerFilter ? "" : provider);
    localStorage.setItem(
      "contactProvider",
      provider === providerFilter ? "" : provider,
    );
  };

  const handleSortClick = (event, data) => {
    setSortCriteria(data.value);
    localStorage.setItem(
      "contactSort",
      data.value === sortCriteria ? "" : data.value,
    );
  };

  const handleContactScroll = (event) => {
    const target = event.target;
    // at bottom in with 280px of bottom of scroll
    const atBottom =
      Math.floor(target.scrollHeight - target.scrollTop - 280) <=
      target.clientHeight;

    if (atBottom && !contactsData.isFetching) {
      contactsData.fetchNextPage();
    }
  };

  useEffect(() => {
    if (contactAreaRef.current) {
      // if there is no scrollbar we need more data
      const shouldLoadMore =
        contactAreaRef.current.scrollHeight <=
        contactAreaRef.current.clientHeight;

      if (shouldLoadMore && contactsData.hasNextPage) {
        contactsData.fetchNextPage();
      }
    }
  }, [contactsData]);

  if (contactsData.isError) {
    toast("Failed to fetch contacts", { type: "error" });
    return null;
  }

  return (
    <>
      {debouncedSearch.length > 0 ? (
        <>
          <Segment basic>
            <Header>Search Results</Header>

            {isSearching() ? (
              <PageLoader />
            ) : (
              <>
                {searchedUsers.length === 0 ? (
                  <p>No results found</p>
                ) : (
                  <Card.Group>
                    {searchedUsers.map((x, i) => (
                      <ContactCard
                        key={`su-${i}`}
                        provider={providers[x.provider]}
                        data={x}
                        limit="true"
                      />
                    ))}
                  </Card.Group>
                )}
              </>
            )}
          </Segment>
        </>
      ) : (
        <>
          <SideScrollerLayout>
            <SideScrollerSidebar>
              <Header style={{ padding: "17px 0px 0px 17px" }}>
                Call History
              </Header>
              <CallLogs />
            </SideScrollerSidebar>
            <SideScrollerContent>
              <div
                ref={contactAreaRef}
                onScroll={handleContactScroll}
                style={{
                  padding: "17px",
                  height: `calc(100vh - 60px)`,
                  overflowY: "scroll",
                  width: "100%",
                }}
              >
                <Menu
                  inverted
                  borderless
                  style={{ border: "none", height: "44px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #767676",
                      borderRadius: "0.57142857rem",
                    }}
                  >
                    <MenuItem header style={{ padding: "0px 17px" }}>
                      Quick filter
                    </MenuItem>
                    <MenuItem style={{ padding: "0" }}>
                      {Object.keys(providers).map((p) => {
                        const provider = providers[p];
                        if (
                          provider.canFilter &&
                          (provider.checkIntegration === false ||
                            (provider.checkIntegration === true &&
                              hasIntegration(provider.value)))
                        ) {
                          return (
                            <Button
                              icon
                              style={{
                                border: "none",
                                backgroundColor: providerFilter.includes(
                                  provider.value,
                                )
                                  ? "white"
                                  : "#1B1B26",
                              }}
                              value={provider.value}
                              title={provider.label}
                              toggle
                              onClick={() =>
                                handleProviderClick(provider.value)
                              }
                              key={provider.value}
                            >
                              <Image
                                src={provider.logo}
                                style={{ width: "20px" }}
                              />
                            </Button>
                          );
                        }
                        return null;
                      })}
                    </MenuItem>
                  </div>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <MenuItem header style={{ padding: "0px 17px" }}>
                      <Icon
                        link
                        name={favouritesToggle ? "star" : "star outline"}
                        onClick={() =>
                          handleFavouritesToggle(!favouritesToggle)
                        }
                        size="large"
                        style={{
                          marginLeft: "15px",
                          color: favouritesToggle ? "gold" : "white",
                        }}
                      />
                    </MenuItem>
                    <MenuItem>
                      <Loader
                        active={
                          contactsData.isFetching &&
                          !contactsData.isLoading &&
                          !contactsData.isFetchingNextPage
                        }
                      />
                    </MenuItem>
                  </div>
                  <MenuItem position="right">
                    <Header as="h4">
                      <HeaderContent>
                        Sort by{" "}
                        <Dropdown
                          inline
                          options={sortOptions}
                          defaultValue={sortCriteria ?? sortOptions[0].value}
                          onChange={handleSortClick}
                        />
                      </HeaderContent>
                    </Header>
                  </MenuItem>
                </Menu>

                {contactsData.isLoading ? (
                  <PageLoader>Loading contacts...</PageLoader>
                ) : (
                  <>
                    {contactsData.data.pages[0].data.length === 0 ? (
                      <>
                        <p>No contacts found</p>
                      </>
                    ) : (
                      <>
                        <Card.Group>
                          {contactsData.data.pages.map((page, i) => (
                            <React.Fragment key={`page-${i}`}>
                              {page.data.map((x, i) => (
                                <ContactCard
                                  data={x}
                                  key={x.id}
                                  provider={providers[x.provider]}
                                  limit="true"
                                  removeQuery={contactsData.remove}
                                />
                              ))}
                            </React.Fragment>
                          ))}

                          {contactsData.data.data?.map((x, i) => {
                            return (
                              <ContactCard
                                data={x}
                                key={x.id}
                                provider={providers[x.provider]}
                                limit="true"
                                remove={contactsData.remove}
                              />
                            );
                          })}
                        </Card.Group>
                      </>
                    )}

                    {contactsData.hasNextPage && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-around",
                          margin: "25px -7px",
                        }}
                      >
                        <Button
                          basic
                          color="teal"
                          onClick={() => contactsData.fetchNextPage()}
                          loading={contactsData.isFetchingNextPage}
                          disabled={
                            !contactsData.hasNextPage ||
                            contactsData.isFetchingNextPage
                          }
                        >
                          {contactsData.hasNextPage
                            ? "Load More"
                            : "Nothing more to load"}
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </SideScrollerContent>
          </SideScrollerLayout>
        </>
      )}
    </>
  );
};

export default Contacts;
