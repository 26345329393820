import { sdk } from "@gc/ipecs-web-sdk";
import debounce from "lodash.debounce";
import { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Icon, Popup } from "semantic-ui-react";
import { useAuth } from "../../contexts/AuthContext";
import { usePhoneControl } from "../../contexts/PhoneControlContext";
import { getFullPresenceDisplay } from "../../helpers/presence-helper";
import PresenceIndicatorDot from "../PresenceIndicatorDot";
import ActiveAudioCall from "../activeCall/ActiveAudioCall";
import ActiveCallActionBar from "../activeCall/ActiveCallActionBar";
import ActiveCallConnecting from "../activeCall/ActiveCallConnecting";
import ActiveCallContainer from "../activeCall/ActiveCallContainer";

const style = {
  push: {
    width: "100%",
    height: "calc(100% - 100px)",
    zIndex: 2,
  },
};

function ActivePhoneControlCall() {
  const {
    minimized,
    activeCall,
    callAnimationRef,
    activeCallParties,
    handleCallAnswered,
    setCallDuration,
    numberInvalid,
    transferNumber,
  } = usePhoneControl();

  const ringing = activeCall?.state === "calling";

  return (
    <ActiveCallContainer minimized={minimized}>
      <div style={style.push}></div>
      {ringing && (
        <ActiveCallConnecting invalid={numberInvalid} minimized={minimized} />
      )}
      {!ringing && activeCall && (
        <ActiveAudioCall
          activeCall={activeCall}
          minimized={minimized}
          activeCallParties={activeCallParties}
          callAnimationRef={callAnimationRef}
          transferNumber={transferNumber}
          handleCallAnswered={handleCallAnswered}
          setCallDuration={setCallDuration}
          numberInvalid={numberInvalid}
        />
      )}
      <ActionBar />
    </ActiveCallContainer>
  );
}

function TransferCall({ setShowTransferBox }) {
  const {
    blindTransferring,
    handleBlindTransfer,
    transferNumber,
    handleTransfer,
    handleTransferComplete,
    handleTransferCancel,
  } = usePhoneControl();
  const { ipecsUser } = useAuth();
  const { user } = sdk;

  const [transferTo, setTransferTo] = useState();
  const [search, setSearch] = useState("");
  const [searching, setSearching] = useState(false);
  const [searchedUsers, setSearchedUsers] = useState([]);

  const debouncedSearch = useRef(
    debounce(async (value) => {
      try {
        const searchParam = {
          term: value,
          field: "displayName,extensionNumber",
          scope: "all",
          limit: 8,
        };
        const _searchedUsers = (await user.searchUsers(searchParam)).filter(
          (x) => x.id !== ipecsUser.myInfo.id,
        );

        const newSearchedUsers = [];

        if (value.length > 0) {
          newSearchedUsers.push({
            key: "input-value",
            value: value,
            text: `"${value}"`,
          });
        }

        _searchedUsers.forEach((user) => {
          user.callNumbers.forEach((number) => {
            newSearchedUsers.push({
              key: user.id + number.value,
              value: number.value,
              text: `${user.name} | ${number.value}`,
              description: number.type,
              content: (
                <>
                  {user.type === "wuc" && (
                    <Popup
                      size="mini"
                      content={getFullPresenceDisplay(
                        user.presence,
                        user.statusMessage,
                      )}
                      trigger={
                        <PresenceIndicatorDot presence={user.presence} />
                      }
                    />
                  )}{" "}
                  {user.name} | {number.value}
                </>
              ),
            });
          });
        });

        setSearchedUsers(newSearchedUsers);
        setSearching(false);
      } catch (e) {
        //
      }
    }, 500),
  ).current;

  const handleChange = (_, data) => {
    setTransferTo(data.value);
  };

  const handleSearchChange = async (e) => {
    setSearching(true);
    setTransferTo();
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  return (
    <div style={{ display: "flex" }}>
      {transferNumber.length > 0 ? (
        <>
          <Popup
            content={"Cancel transfer"}
            trigger={
              <Button
                color="purple"
                onClick={() => {
                  handleTransferCancel();
                  setShowTransferBox(false);
                }}
              >
                <Icon name="times circle" />
                Cancel Transfer
              </Button>
            }
          />

          <Popup
            content={"Confirm transfer"}
            trigger={
              <Button color="green" onClick={() => handleTransferComplete()}>
                <Icon name="circle check" />
                Confirm Transfer
              </Button>
            }
          />
        </>
      ) : (
        <>
          <Dropdown
            style={{ width: "300px" }}
            placeholder="Search or enter number"
            fluid
            search
            selection
            disabled={blindTransferring || transferNumber.length > 0}
            upward
            loading={searching}
            noResultsMessage={
              search.length <= 0 ? "Search or enter number" : "Searching"
            }
            onChange={handleChange}
            onSearchChange={handleSearchChange}
            options={searchedUsers}
          />

          <Popup
            content={"Confirm blind transfer"}
            trigger={
              <Button
                loading={blindTransferring || transferNumber.length > 0}
                disabled={
                  !transferTo || blindTransferring || transferNumber.length > 0
                }
                color="purple"
                onClick={() => handleBlindTransfer(transferTo)}
              >
                <Icon name="circle check" />
                Blind Transfer
              </Button>
            }
          />

          <Popup
            content={"Attended transfer"}
            trigger={
              <Button
                loading={blindTransferring || transferNumber.length > 0}
                disabled={
                  !transferTo || blindTransferring || transferNumber.length > 0
                }
                color="green"
                onClick={() => handleTransfer(transferTo)}
              >
                <Icon name="circle check" />
                Transfer
              </Button>
            }
          />
        </>
      )}
    </div>
  );
}

function ActionBar() {
  const {
    minimized,
    setMinimized,
    disconnecting,
    activeCall,
    consultationCall,
    handleHangup,
    transferNumber,
    blindTransferring,
  } = usePhoneControl();

  const [showTransferBox, setShowTransferBox] = useState(false);
  const hideActions = () => showTransferBox;

  // handle remote end hangup during transfer
  useEffect(() => {
    if (!consultationCall) setShowTransferBox(false);
  }, [consultationCall]);

  return (
    <ActiveCallActionBar
      minimized={minimized}
      setMinimized={setMinimized}
      disconnecting={disconnecting}
      activeCall={activeCall}
      handleHangup={handleHangup}
      hideDefaultActions={hideActions()}
    >
      {showTransferBox && (
        <div style={{ display: "flex" }}>
          <TransferCall setShowTransferBox={setShowTransferBox} />
          {transferNumber.length === 0 && (
            <CancelButton
              tooltip="Cancel transfer"
              onClick={() => setShowTransferBox(false)}
              disabled={blindTransferring}
            />
          )}
        </div>
      )}
      {!hideActions() && (
        <>
          <Popup
            content={"Transfer call"}
            trigger={
              <Button
                color="black"
                disabled={activeCall?.state !== "answered"}
                onClick={() => {
                  setShowTransferBox(true);
                }}
                icon
              >
                <Icon name="exchange" />
              </Button>
            }
          />
        </>
      )}
    </ActiveCallActionBar>
  );
}

function CancelButton({ tooltip, onClick, disabled = false }) {
  return (
    <Popup
      content={tooltip}
      trigger={
        <Button color="black" onClick={onClick} disabled={disabled} icon>
          <Icon name="close" />
        </Button>
      }
    />
  );
}

export default ActivePhoneControlCall;
