import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Card, Container, Segment } from "semantic-ui-react";
import { getWhatsNew, setWhatsNewRead } from "../api/whats-new";
import PageLoader from "../components/PageLoader";
import { useAuth } from "../contexts/AuthContext";

function WhatsNew() {
  const { setApiUser } = useAuth();

  const whatsNewWuery = useQuery(
    ["whats-new"],
    () => {
      return getWhatsNew();
    },
    {
      keepPreviousData: true,
      cacheTime: 3600000, // one hour
      staleTime: 600000, // 10 mins
    }
  );

  useEffect(() => {
    setWhatsNewRead().then((r) => setApiUser(r.data?.data));
  }, [setApiUser]);

  if (whatsNewWuery.isFetching) return <PageLoader>Loading...</PageLoader>;

  return (
    <Container>
      <Segment basic>
        <h1>What's New</h1>

        {whatsNewWuery.data?.data?.data.length === 0 && (
          <WhatsNewCard
            data={{
              title: "New features and updates are on their way!",
              body: "<p>Welcome to our 'What's New' page! While we don't have any updates to share just yet, we're constantly working behind the scenes to bring you exciting changes and improvements.</p><p>Stay tuned for future announcements. We can't wait to share our latest developments with you soon!<p>",
            }}
          />
        )}

        {whatsNewWuery.data?.data?.data.map((x, i) => (
          <WhatsNewCard key={i} data={x} />
        ))}

        <Card.Group></Card.Group>
      </Segment>
    </Container>
  );
}

function WhatsNewCard({ data }) {
  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>{data.title}</Card.Header>
        <div
          style={{ marginTop: "12px" }}
          dangerouslySetInnerHTML={{ __html: data.body }}
        />
      </Card.Content>
    </Card>
  );
}

export default WhatsNew;
