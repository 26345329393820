import { useParams } from "react-router-dom";
import { useChat } from "../contexts/ChatContext";
import SideScrollerLayout, {
  SideScrollerContent,
  SideScrollerSidebar,
} from "./SideScrollerLayout";
import { MessageListItem } from "./messaging/MessageListItem";

function ChatContainer({ children }) {
  const { rooms } = useChat();
  const { id } = useParams();

  return (
    <SideScrollerLayout>
      <SideScrollerSidebar>
        {rooms
          .filter((x) => x.type === "Chat")
          .map((x, i) => {
            return (
              <MessageListItem
                key={`chat-${i}`}
                id={id}
                chatId={x.id}
                chatUrl={`/chat/${x.id}`}
                color="teal"
                unreadCount={x.unReadCount}
                avatar={x.avatars[0]}
                displayName={x.displayName}
                timestamp={x.message.timestamp}
                message={x.message.value}
              />
            );
          })}
      </SideScrollerSidebar>
      <SideScrollerContent>{children}</SideScrollerContent>
    </SideScrollerLayout>
  );
}

export default ChatContainer;
