import { Button, Form, Input } from "semantic-ui-react";
import YesNoToggle from "./YesNoToggle";

function SidekickDetails({
  configured,
  sidekick,
  onChange,
  onSubmit,
  onClickBack,
  submitting,
}) {
  return (
    <>
      <Form onSubmit={onSubmit}>
        <Form.Field>
          <label>Is this a Sidekick order?</label>
          <YesNoToggle value={sidekick.required} onChange={onChange} />
        </Form.Field>

        {sidekick.required && (
          <>
            {!configured && (
              <>
                <Form.Field>
                  <label>Admin email address</label>
                  <p>
                    An initial user will be created as an admin. This user can
                    then add and assign Sidekick licences to other users.
                  </p>
                  <Input
                    type="email"
                    name="admin_email"
                    onChange={onChange}
                    value={sidekick.admin_email}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Admin extension</label>
                  <Input
                    type="text"
                    name="admin_extension"
                    onChange={onChange}
                    value={sidekick.admin_extension}
                  />
                </Form.Field>
              </>
            )}
            <Form.Field>
              <label>Quantity of Sidekick licenses</label>
              <p>
                Please select the quantity of Sidekick licences required. This
                is 1 per user.
              </p>
              <Input
                type="number"
                name="quantity"
                onChange={onChange}
                value={sidekick.quantity}
              />
            </Form.Field>
          </>
        )}

        <Button.Group>
          <Button onClick={onClickBack}>Back</Button>
          <Button.Or />
          <Button loading={submitting} primary>
            Next
          </Button>
        </Button.Group>
      </Form>
    </>
  );
}

export default SidekickDetails;
