import axios from "axios";
import config from "../config";

export function createOrderForm(data) {
  return axios.post(`${config.api_url}/order-forms`, data);
}

export function updateOrderForm(id, data) {
  return axios.put(`${config.api_url}/order-forms/${id}`, data);
}

export function submitOrderForm(id) {
  return axios.put(`${config.api_url}/order-forms/${id}/submit`);
}
