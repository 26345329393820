import { motion } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Dropdown, Menu, MenuItem, Popup } from "semantic-ui-react";
import { useAuth } from "../contexts/AuthContext";
import { useCalls } from "../contexts/CallsContext";
import { useInstallPrompt } from "../contexts/InstallPromptContext";
import { useMyPresence } from "../contexts/MyPresenceContext";
import layout from "../layout";
import logo from "../logos/icon_white.svg";
import Avatar from "./Avatar";

const presenceOptions = [
  { text: "Online", value: "online" },
  { text: "Busy", value: "busy" },
  { text: "DND", value: "dnd" },
];

const style = {
  logoArea: {
    width: layout.sidebarWidth,
    marginRight: "10px",
    textAlign: "center",
  },
  logo: {
    height: "24px",
  },
  topbar: {
    paddingRight: "10px",
    height: layout.topbarHeight,
    margin: 0,
    alignItems: "center",
  },
  items: { justifyContent: "flex-end", flexGrow: "1" },
  rightItems: { display: "flex", gap: "12px" },
};

function Topbar({ children }) {
  const { presence, changePresence, getPresenceColor, getPresenceIcon } =
    useMyPresence();
  const { hasActiveCall } = useCalls();
  const { isPhoneControl } = useAuth();

  const handleChange = async (_, data) => changePresence(data.value);

  return (
    <div className="ui inverted menu" style={style.topbar}>
      <div style={style.logoArea}>
        <img style={style.logo} src={logo} alt="Logo" />
      </div>
      <div style={style.items}>{children}</div>

      <div style={style.rightItems}>
        <Popup
          position="bottom center"
          content={`You are currently in ${
            isPhoneControl ? "phone control" : "softphone"
          } mode`}
          trigger={
            <Button
              icon={isPhoneControl ? "phone" : "text telephone"}
              color="grey"
              basic
            />
          }
        />

        {hasActiveCall() || presence === "in_a_call" ? (
          <Dropdown
            disabled
            button
            style={{ width: "128px" }}
            className="icon red"
            floating
            labeled
            icon="dont"
            options={[{ text: "In a call", value: "in_a_call" }]}
            value="in_a_call"
          />
        ) : (
          <Dropdown
            button
            style={{ width: "128px" }}
            className={`ui form small icon ${getPresenceColor(presence)}`}
            floating
            labeled
            icon={getPresenceIcon(presence)}
            options={presenceOptions}
            value={presence}
            onChange={handleChange}
          />
        )}

        <AvatarMenu />
      </div>
    </div>
  );
}

function AvatarMenu() {
  const [open, setOpen] = useState(false);
  const { ipecsUser, apiUser } = useAuth();
  const { prompt, promptToInstall } = useInstallPrompt();
  const navigate = useNavigate();

  return (
    <Popup
      style={{ backgroundColor: "transparent", border: "none" }}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={
        <div>
          <AlertAnimation enabled={apiUser.whats_new && !open}>
            <Avatar
              src={ipecsUser.myInfo.avatar}
              style={{ cursor: "pointer", fontSize: 18 }}
              avatar
            />
          </AlertAnimation>
        </div>
      }
      content={
        <Menu compact vertical inverted>
          <MenuItem>{ipecsUser.myInfo.name}</MenuItem>
          {prompt && <MenuItem onClick={promptToInstall}>Install</MenuItem>}
          <MenuItem onClick={() => navigate("/settings")}>Settings</MenuItem>
          <MenuItem onClick={() => navigate("/whats-new")}>
            <AlertAnimation enabled={apiUser.whats_new}>
              What's New
            </AlertAnimation>
          </MenuItem>
          <MenuItem
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.connect.cosoft.co.uk"
          >
            Documentation
          </MenuItem>
          <MenuItem onClick={() => navigate("/settings/logout")}>
            Logout
          </MenuItem>
        </Menu>
      }
      on="click"
      position="bottom left"
    />
  );
}

function AlertAnimation({ enabled, children }) {
  if (!enabled) return children;

  return (
    <motion.div
      animate={{ y: [-10, 0] }}
      transition={{
        type: "spring",
        repeat: Infinity,
        stiffness: 500,
        duration: 1,
        repeatDelay: 1,
      }}
    >
      {children}
    </motion.div>
  );
}

export default Topbar;
