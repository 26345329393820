import { sdk } from "@gc/ipecs-web-sdk";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { getPresences } from "../api/presences";
import { useAuth } from "./AuthContext";
import { useMyPresence } from "./MyPresenceContext";

const PresenceContext = React.createContext();
const PresenceProvider = ({ children }) => {
  const { presence } = sdk;
  const [eventChangedPresence, setEventChangedPresence] = useState();
  const [presences, setPresences] = useState([]);
  const { ipecsUser } = useAuth();
  const { setPresence } = useMyPresence();

  const handleAllPresences = useCallback(() => {
    getPresences()
      .then((res) => {
        setPresences(
          res.data.map((x) => ({
            userId: x.user_id,
            presence: x.status,
            statusMessage: x.status_message,
          })),
        );
      })
      .catch((e) => {});
  }, []);

  useEffect(() => handleAllPresences(), [handleAllPresences]);

  const getPresenceByUserId = async (userId) => {
    const index = presences.findIndex((obj) => obj.userId === userId);

    if (index !== -1) {
      return presences[index];
    }

    const _presences = Array.from(presences);
    let _newData = { userId: userId, presence: "unknown", statusMessage: "" };

    _presences.push(_newData);
    setPresences(_presences);
    return _newData;
  };

  useEffect(() => {
    presence.onChangePresence((changedPresences) => {
      setEventChangedPresence(changedPresences);
    });
  }, [presence]);

  useEffect(() => {
    if (eventChangedPresence && eventChangedPresence.length > 0) {
      const _presences = Array.from(presences);

      eventChangedPresence.map((e) => {
        if (e.userId === ipecsUser.myInfo.id) {
          setPresence(e.presence);
        } else {
          const index = presences.findIndex((obj) => obj.userId === e.userId);
          if (index === -1) {
            _presences.push(e);
          } else {
            if (e.presence !== undefined)
              _presences[index].presence = e.presence;
            if (e.statusMessage !== undefined)
              _presences[index].statusMessage = e.statusMessage;
          }
        }

        return e;
      });

      setPresences(_presences);
      setEventChangedPresence();
    }
  }, [
    eventChangedPresence,
    ipecsUser.myInfo.id,
    presence,
    presences,
    setPresence,
  ]);

  const defaultContext = { getPresenceByUserId };
  return (
    <PresenceContext.Provider value={defaultContext}>
      {children}
    </PresenceContext.Provider>
  );
};

function usePresence() {
  return useContext(PresenceContext);
}

export { PresenceProvider, usePresence };
