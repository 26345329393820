import { sdk } from "@gc/ipecs-web-sdk";
import { useState } from "react";
import {
  Button,
  Container,
  Form,
  Header,
  Icon,
  Message,
} from "semantic-ui-react";
import PageLoader from "../components/PageLoader";
import logo from "../logos/white.svg";

const style = {
  container: {
    padding: "3rem",
  },
  form: {
    maxWidth: "460px",
    margin: "auto",
  },
  submit: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  logo: {
    width: "240px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "4rem",
  },
  removeDevices: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
};

const getLoginParams = (username, password, server, apiKey) => {
  return {
    deviceId: "5991fd0-29d1-4f88-99cc-865cf1e2f2fe1",
    deviceDisplayName: "Connect",
    username: username,
    password: password,
    serverUrl: server,
    isPhoneControl: false,
    removeDeviceIds: [],
    customerApplicationApiKey: apiKey,
  };
};

function ConfigChecker() {
  return (
    <Container fluid style={style.container}>
      <div style={style.logo}>
        <img src={logo} alt="Logo" />
      </div>

      <LoginForm />
    </Container>
  );
}

function LoginForm() {
  const [loading, setLoading] = useState(false);
  const [serverUrl, setServerUrl] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const { auth } = sdk;

  async function handleLogin() {
    if ([username, password, serverUrl, apiKey].filter((x) => x).length !== 4) {
      setError("All fields are required");
      return;
    }

    setLoading(true);

    try {
      await auth.login(getLoginParams(username, password, serverUrl, apiKey));
      setSuccess(true);
      // now logout as we don't need to logged in for this
      auth.logout();
    } catch (error) {
      switch (error.message) {
        case "E_OVER_MAX_DEVICES":
          // although we have an error, the config itself is correct
          setSuccess(true);
          break;
        case "E_INVALID_INPUT":
          setError(
            `${error.message} - This usually means the API key or the extension credentials are incorrect.`,
          );
          break;
        case "E_FAIL_TO_CONNECT_SERVER":
          setError(
            `${error.message} - This usually means the server URL is incorrect.`,
          );
          break;
        default:
          setError(error.message);
          break;
      }
    }

    setLoading(false);
  }

  const handleChange = (value, setter) => {
    setSuccess(false);
    setError("");
    setter(value);
  };

  const startAgain = () => {
    setServerUrl("");
    setApiKey("");
    setUsername("");
    setPassword("");
    setSuccess(false);
  };

  if (loading) return <PageLoader />;

  return (
    <div style={style.form}>
      <Header>iPECS Config Checker</Header>

      {success ? (
        <div>
          <Message positive>
            <Message.Header>Config Valid</Message.Header>
            <p>We have successfully managed to connect to iPECS</p>
          </Message>

          <Button primary onClick={startAgain}>
            Start again
          </Button>
        </div>
      ) : (
        <Form onSubmit={() => handleLogin([])}>
          <Form.Field>
            <label>Server URL</label>
            <input
              placeholder="Server URL"
              value={serverUrl}
              onChange={(e) =>
                handleChange(e.target.value.trim(), setServerUrl)
              }
            />
          </Form.Field>

          <Form.Field>
            <label>Tenant API Key</label>
            <PasswordField
              placeholder="API Key"
              value={apiKey}
              onChange={(e) => handleChange(e.target.value.trim(), setApiKey)}
            />
          </Form.Field>

          <Form.Field>
            <label>Extension Username</label>
            <input
              placeholder="Username"
              value={username}
              onChange={(e) => handleChange(e.target.value.trim(), setUsername)}
            />
          </Form.Field>

          <Form.Field>
            <label>Extension Password</label>
            <PasswordField
              placeholder="Password"
              value={password}
              onChange={(e) => handleChange(e.target.value.trim(), setPassword)}
            />
          </Form.Field>

          <Button
            style={style.submit}
            type="submit"
            loading={loading}
            disabled={loading}
            primary
          >
            Submit
          </Button>

          {error.length > 0 && (
            <Message negative>
              <Message.Header>Invalid Config</Message.Header>
              <p>{error}</p>
            </Message>
          )}
        </Form>
      )}
    </div>
  );
}

function PasswordField({ ...props }) {
  const [visible, setVisible] = useState(false);

  return (
    <Form.Input iconPosition="right">
      <Icon
        name={visible ? "eye slash" : "eye"}
        link
        onClick={() => setVisible(!visible)}
      />
      <input type={visible ? "text" : "password"} {...props} />
    </Form.Input>
  );
}

export default ConfigChecker;
