import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

function PWA() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const link = searchParams.get("link");
    const decoded = decodeURI(link);
    const cleaned = decoded.replace("web+ipecsconnect:", "");
    navigate(`/${cleaned}`);
  }, [searchParams, navigate]);

  return null;
}

export default PWA;
