import logo from "../logos/white.svg";

const style = {
  container: {
    padding: "3rem",
  },
  logo: {
    width: "240px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "4rem",
  },
};

function LoginFormLogo() {
  return (
    <div style={style.logo}>
      <img src={logo} alt="Logo" />
    </div>
  );
}

export default LoginFormLogo;
