import tone0 from "./0.mp3";
import tone1 from "./1.mp3";
import tone2 from "./2.mp3";
import tone3 from "./3.mp3";
import tone4 from "./4.mp3";
import tone5 from "./5.mp3";
import tone6 from "./6.mp3";
import tone7 from "./7.mp3";
import tone8 from "./8.mp3";
import tone9 from "./9.mp3";
import star from "./star.mp3";
import hash from "./hash.mp3";

export const tones = {
  0: tone0,
  1: tone1,
  2: tone2,
  3: tone3,
  4: tone4,
  5: tone5,
  6: tone6,
  7: tone7,
  8: tone8,
  9: tone9,
  "*": star,
  "#": hash,
};
