import { Link } from "react-router-dom";
import { Label } from "semantic-ui-react";
import { getShortTimestamp } from "../../helpers/timestamp-helper";
import Avatar from "../Avatar";
import { SideScrollerSidebarItem } from "../SideScrollerLayout";

export function MessageListItem({
  id,
  chatId,
  color,
  hasUnread,
  avatar,
  displayName,
  timestamp,
  message,
  chatUrl,
}) {
  return (
    <SideScrollerSidebarItem
      as={Link}
      to={chatUrl}
      active={chatId === id}
      color={color}
      key={chatId}
      style={{
        height: "58px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {avatar && (
          <Avatar
            src={avatar}
            circular
            style={{ width: "30px", marginRight: "15px" }}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <strong>{displayName}</strong>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div>{getShortTimestamp(timestamp)}</div>
              {hasUnread && <Label color="red" circular empty size="large" />}
            </div>
          </div>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {message}
          </div>
        </div>
      </div>
    </SideScrollerSidebarItem>
  );
}
