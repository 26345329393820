import { Container, Segment } from "semantic-ui-react";
import Lottie from "react-lottie";
import notFoundAnimation from "../animations/notfound.json";
import { Link } from "react-router-dom";

const style = {
  container: {
    paddingTop: "6rem",
    padding: "3rem",
    textAlign: "center",
    display: "flex",
    flexDirection: " column",
    justifyContent: "center",
    alignItems: "center",
  },
};

function NotFound() {
  return (
    <Container>
      <Segment basic style={style.container}>
        <h1>Page not found</h1>
        <p>That page is nowhere to be found.</p>
        <p>
          <Link to="/">Go to homepage</Link>
        </p>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: notFoundAnimation,
          }}
          height={600}
        />
      </Segment>
    </Container>
  );
}

export default NotFound;
