import LogRocket from "logrocket";
import { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";
import AppRoutes from "./Routes";
import NeedChrome from "./components/NeedChrome";
import { logrocket as lrconfig } from "./config";
import SplashScreen from "./components/SplashScreen";
import { useAuth } from "./contexts/AuthContext";

function App() {
  const { apiUser } = useAuth();
  const [isChrome, setIsChrome] = useState();
  const [lrInit, setLrInit] = useState(false);
  const [continueAnyway, setContinueAnyway] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const _isChrome = !!window.chrome;
    setIsChrome(_isChrome);
    if (_isChrome) {
      Notification.requestPermission();
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (lrconfig !== undefined) {
      LogRocket.init(lrconfig, {
        network: {
          requestSanitizer: (request) => {
            //request.body = null;
            return request;
          },
        },
      });
      setLrInit(true);
    }
  }, []);

  useEffect(() => {
    if (lrconfig !== undefined && apiUser && lrInit) {
      LogRocket.identify(apiUser.email, {
        name: `${apiUser.first_name} ${apiUser.last_name}`,
        // Add your own custom user variables here, ie:
        email: apiUser.email,
      });
    }
  }, [lrInit, apiUser]);

  if (loading) return <SplashScreen />;

  return isChrome || continueAnyway ? (
    <AppRoutes />
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <NeedChrome />
      <Button primary onClick={() => setContinueAnyway(true)}>
        Continue Anyway
      </Button>
    </div>
  );
}

export default App;
