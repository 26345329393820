import { useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import ringtone from "../../audio/ringtone.mp3";
import { usePhoneControl } from "../../contexts/PhoneControlContext";
import IncomingCallAlert, { Popup } from "../activeCall/IncomingCallAlert";

function IncomingPhoneControlCallAlert() {
  const {
    incomingCall,
    incomingCallPartyInfo,
    handleAnswerIncoming,
    handleRejectIncoming,
    disconnecting,
  } = usePhoneControl();

  const [incomingAnswering, setIncomingAnswering] = useState(false);
  const [incomingRejecting, setIncomingRejecting] = useState(false);

  const handleAnswerWithAudio = () => {
    setIncomingAnswering(true);
    handleAnswerIncoming()
      .then(() => setIncomingAnswering(false))
      .catch(() => setIncomingAnswering(false));
  };

  const handleReject = () => {
    setIncomingRejecting(true);
    handleRejectIncoming()
      .then(() => setIncomingRejecting(false))
      .catch(() => setIncomingRejecting(false));
  };

  return (
    <>
      <audio src={ringtone} autoPlay loop></audio>
      <IncomingCallAlert
        name={incomingCallPartyInfo?.name ?? incomingCall.callNumber}
        number={incomingCall.callNumber}
      >
        <div className="ui two buttons">
          <Popup
            content="Answer"
            trigger={
              <Button
                color="green"
                onClick={handleAnswerWithAudio}
                loading={incomingAnswering}
                disabled={
                  incomingAnswering || incomingRejecting || disconnecting
                }
                icon
              >
                <Icon name="phone" />
              </Button>
            }
          />

          <Popup
            content="Reject"
            trigger={
              <Button
                color="red"
                onClick={handleReject}
                disabled={
                  incomingAnswering || incomingRejecting || disconnecting
                }
              >
                <Icon name="user close" />
              </Button>
            }
          />
        </div>
      </IncomingCallAlert>
    </>
  );
}

export default IncomingPhoneControlCallAlert;
