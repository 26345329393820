import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { uploadWhatsappMedia } from "../api/whatsapp";

export const useSendMediaMessage = (threadId) => {
  const queryClient = useQueryClient();

  const { mutate: sendWhatsappMedia, isLoading: isSending } = useMutation({
    mutationFn: async (e) => {
      e.preventDefault();
      await uploadWhatsappMedia(threadId, e.target.files[0]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["whatsapp-threads"],
      });
      queryClient.invalidateQueries({
        queryKey: ["whatsapp-messages", threadId],
      });
    },
    onError: (error) => {
      toast.error(error?.response?.data?.error || "Failed to send message");
    },
  });

  return { sendWhatsappMedia, isSending };
};
