import { Button, Form, Input } from "semantic-ui-react";
import YesNoToggle from "./YesNoToggle";

function WhatsappDetails({
  submitting,
  whatsapp,
  onChange,
  onSubmit,
  onClickBack,
}) {
  return (
    <>
      <Form onSubmit={onSubmit}>
        <Form.Field>
          <label>Is this a WhatsApp order</label>
          <YesNoToggle value={whatsapp.required} onChange={onChange} />
        </Form.Field>
        {whatsapp.required && (
          <Form.Field>
            <label>Quantity of WhatsApp licenses</label>
            <Input
              type="number"
              name="quantity"
              onChange={onChange}
              value={whatsapp.quantity}
            />
          </Form.Field>
        )}

        <Button.Group>
          <Button onClick={onClickBack}>Back</Button>
          <Button.Or />
          <Button loading={submitting} primary>
            Next
          </Button>
        </Button.Group>
      </Form>
    </>
  );
}

export default WhatsappDetails;
