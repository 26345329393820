import { Card } from "semantic-ui-react";
import {
  getFormattedTimestamp,
  getFriendlyTimestamp,
} from "../../helpers/timestamp-helper";
import Avatar from "../Avatar";
import PresenceIndicator from "../PresenceIndicator";
import MessageContent from "./MessageContent";
import { style } from "./style";

export function MessageBubble({
  message,
  avatar,
  messageType,
  downloadUrl,
  fileName,
  byMe,
  isDeleted,
  senderId,
  senderName,
  timestamp,
  caption,
  highlightColor,
}) {
  return (
    <div
      style={{
        marginBottom: "12px",
        display: "flex",
        justifyContent: byMe ? "flex-end" : "flex-start",
      }}
    >
      <Card
        style={{
          display: "inline-block",
          width: "unset",
          minWidth: "200px",
          maxWidth: "90%",
          backgroundColor: byMe ? highlightColor : "",
        }}
      >
        <Card.Content>
          <Card.Header
            style={{
              color: byMe ? "#1b1b27" : "#fff",
              fontSize: "1.1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            {avatar && (
              <>
                <Avatar src={avatar} circular style={style.avatar} />
                <PresenceIndicator
                  style={{ position: "absolute", top: "36px", left: "36px" }}
                  userId={senderId}
                />
              </>
            )}
            <span
              style={{
                marginLeft: avatar ? "20px" : "0px",
                color: byMe ? "#fff" : highlightColor,
              }}
            >
              {senderName}
            </span>
            <span
              style={{
                marginLeft: "15px",
                color: byMe ? "#fff" : "",
                opacity: "0.5",
              }}
              title={getFormattedTimestamp(timestamp)}
            >
              {getFriendlyTimestamp(timestamp)}
            </span>
          </Card.Header>
          <Card.Description
            style={{
              whiteSpace: "pre-wrap",
              wordBreak: "break-all",
              color: byMe ? "#1b1b27" : "#fff",
              paddingLeft: avatar ? "58px" : "0px",
            }}
          >
            <MessageContent
              message={message}
              messageType={messageType ? messageType : "engage"}
              isDeleted={isDeleted}
              downloadUrl={downloadUrl}
              fileName={fileName}
              caption={caption}
              byMe={byMe}
            />
          </Card.Description>
        </Card.Content>
      </Card>
    </div>
  );
}
