import { sdk } from "@gc/ipecs-web-sdk";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { updatePresence } from "../api/presence";
import { useAuth } from "./AuthContext";

const MyPresenceContext = React.createContext();
const MyPresenceProvider = ({ children }) => {
  const { presence: sdkPresence } = sdk;
  const { ipecsUser } = useAuth();
  const [presence, setPresence] = useState(ipecsUser.myInfo.presence);

  const getPresenceColor = (newPresence) => {
    const presenceToUse = newPresence === undefined ? presence : newPresence;
    switch (presenceToUse) {
      case "dnd":
      case "busy":
      case "in_a_call":
      case "offline":
        return "red";
      default:
        return "green";
    }
  };

  const getPresenceIcon = (newPresence) => {
    const presenceToUse = newPresence === undefined ? presence : newPresence;
    switch (presenceToUse) {
      case "dnd":
      case "offline":
      case "in_a_call":
        return "dont";
      case "busy":
        return "microphone slash";
      default:
        return "world";
    }
  };

  const changePresence = async (newValue) => {
    const oldValue = presence;
    setPresence(newValue);

    try {
      await sdkPresence.setPresence({
        presence: newValue,
        statusMessage: "",
      });

      updatePresence({ status: newValue });
    } catch (e) {
      setPresence(oldValue);
      console.error(e);
      toast("Failed to update presence", { type: "error" });
    }
  };

  const defaultContext = {
    presence,
    setPresence,
    changePresence,
    getPresenceColor,
    getPresenceIcon,
  };
  return (
    <MyPresenceContext.Provider value={defaultContext}>
      {children}
    </MyPresenceContext.Provider>
  );
};

function useMyPresence() {
  return useContext(MyPresenceContext);
}

export { MyPresenceProvider, useMyPresence };
