import { useState } from "react";

const useEmojiPicker = ({ setMessage }) => {
  const [emojiOpen, setEmojiOpen] = useState(false);

  const handleOpenEmoji = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setEmojiOpen(true);
  };

  const handleSelectEmoji = (emoji, e) => {
    e.stopPropagation();
    setMessage((message) => message + emoji.native);
    setEmojiOpen(false);
  };

  const handleCloseEmoji = (e) => {
    setEmojiOpen(false);
  };

  const emojiPickerProps = {
    emojiOpen,
    handleOpenEmoji,
    handleSelectEmoji,
    handleCloseEmoji,
  };

  return emojiPickerProps;
};

export default useEmojiPicker;
