import layout from "../../layout";

const headerAreaHeight = "60px";
const replyAreaHeight = "160px";
const maxWidth = "1024px";

export const style = {
  container: {
    height: `calc(100vh - ${layout.topbarHeight})`,
    overflowY: "hidden",
    width: "100%",
  },
  header: {
    padding: "12px",
    height: headerAreaHeight,
    maxWidth: maxWidth,
    margin: "auto",
  },
  messages: {
    display: "flex",
    flexDirection: "column-reverse",
    padding: "12px",
    height: `calc(100% - ${replyAreaHeight} - 4px)`,
    overflowY: "scroll",
    width: "100%",
  },
  chatMessages: {
    display: "flex",
    flexDirection: "column-reverse",
    padding: "12px",
    height: `calc(100% - ${replyAreaHeight} - ${headerAreaHeight} - 4px)`,
    overflowY: "scroll",
    width: "100%",
  },
  messageGroup: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: maxWidth,
  },
  avatar: { width: "35px" },
  reply: {
    container: {
      margin: "auto",
      height: "160px",
      maxWidth: "1024px",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      alignItems: "flex-end",
    },
    textArea: {
      resize: "none",
      width: "100%",
      height: "100px",
      marginLeft: "auto",
      marginRight: "auto",
      padding: "12px",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "5px",
      alignItems: "center",
    },
    actionButton: { marginRight: "15px", border: "none" },
  },
};
