import { sdk } from "@gc/ipecs-web-sdk";
import React, { useCallback, useContext, useEffect, useState } from "react";

const CallLogsContext = React.createContext();
const CallLogsProvider = ({ children }) => {
  const { callLog } = sdk;

  const [callLogs, setCallLogs] = useState(null);
  const [missedCallCount, setMissedCallCount] = useState(0);

  const handleCallLogs = useCallback(() => {
    callLog.getMissedCallCount().then((res) => setMissedCallCount(res));
  }, [callLog]);

  useEffect(() => handleCallLogs(), [handleCallLogs]);

  const refetchMissedCallCount = () => {
    callLog.getMissedCallCount().then((res) => setMissedCallCount(res));
  };

  const refetchCallLogs = () => {
    callLog.getCallLogs().then((res) => setCallLogs(res.callLogs));
  };

  const defaultContext = {
    callLogs,
    setCallLogs,
    missedCallCount,
    setMissedCallCount,
    refetchMissedCallCount,
    refetchCallLogs,
  };

  return (
    <CallLogsContext.Provider value={defaultContext}>
      {children}
    </CallLogsContext.Provider>
  );
};

function useCallLogs() {
  return useContext(CallLogsContext);
}

export { CallLogsProvider, useCallLogs };
