import { useInfiniteQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "semantic-ui-react";
import { getEngageChats } from "../api/engage";
import SideScrollerLayout, {
  SideScrollerContent,
  SideScrollerSidebar,
} from "./SideScrollerLayout";
import { MessageListItem } from "./messaging/MessageListItem";

function EngageContainer({ children }) {
  const { id } = useParams();

  const chatsQuery = useInfiniteQuery({
    queryKey: ["engage-chats"],
    queryFn: async ({ pageParam = 1 }) => {
      return await getEngageChats({ page: pageParam });
    },
    getNextPageParam: (lastPage) =>
      lastPage.data.meta.current_page < lastPage.data.meta.last_page
        ? lastPage.data.meta.current_page + 1
        : undefined,
    keepPreviousData: true,
    cacheTime: 60 * 60 * 1000,
  });

  if (chatsQuery.isError) {
    toast("Failed to load Engage chats", { type: "error" });
    return null;
  }

  return (
    <SideScrollerLayout>
      <SideScrollerSidebar>
        {!chatsQuery.isLoading &&
          chatsQuery?.data &&
          chatsQuery?.data?.pages.map((page) => {
            return (
              <React.Fragment key={`chats-page-${page.data.meta.current_page}`}>
                {page.data.data.map((x) => {
                  return (
                    <MessageListItem
                      key={x.id}
                      id={id}
                      chatId={x.id}
                      chatUrl={`/engage/${x.id}`}
                      color="purple"
                      hasUnread={x.has_unread}
                      displayName={x.name}
                      timestamp={x.last_message_millis}
                    />
                  );
                })}
              </React.Fragment>
            );
          })}
        {chatsQuery.hasNextPage && (
          <div
            style={{ width: "100%", textAlign: "center", padding: "15px 0px" }}
          >
            <Button
              color="purple"
              onClick={() => {
                chatsQuery.fetchNextPage();
              }}
              disabled={chatsQuery.isFetchingNextPage}
              loading={chatsQuery.isFetchingNextPage}
            >
              Load more
            </Button>
          </div>
        )}
      </SideScrollerSidebar>
      <SideScrollerContent>{children}</SideScrollerContent>
    </SideScrollerLayout>
  );
}

export default EngageContainer;
