import { Button, Form, FormField, Message } from "semantic-ui-react";

function CustomerDetails({
  newCustomer = false,
  customer,
  searching,
  onChange,
  error,
  onSubmit,
  onSearch,
  onStartAgain,
  submitting,
}) {
  return newCustomer ? (
    <Form onSubmit={onSubmit}>
      <p>
        No customer found with this billing ID. Please enter new customer
        details below:
      </p>
      <FormField>
        <label>Billing ID</label>
        <p>This may be referred to as the Customer ID or CLI</p>
        <input
          name="billing_id"
          onChange={onChange}
          value={customer.billing_id}
        />
      </FormField>

      <FormField>
        <label>Reseller Name</label>
        <input
          name="reseller_name"
          onChange={onChange}
          value={customer.reseller_name}
        />
      </FormField>
      <FormField>
        <label>Customer Name</label>
        <input
          name="customer_name"
          onChange={onChange}
          value={customer.customer_name}
        />
      </FormField>

      <Button.Group>
        <Button onClick={onStartAgain}>Start again</Button>
        <Button.Or />
        <Button loading={submitting} primary>
          Next
        </Button>
      </Button.Group>
    </Form>
  ) : (
    <Form onSubmit={onSearch} error={error?.content ? true : false}>
      <p>
        To get started, please enter the customer's billing ID. For new
        customers, you will then be prompted for further details.
      </p>
      <FormField error={error?.content ? true : false}>
        <label>Billing ID</label>
        <p>This may be referred to as the Customer ID or CLI</p>
        <Form.Input
          error={error?.content ? true : false}
          action={{
            content: "Search",
            primary: true,
            loading: searching,
          }}
          name="billing_id"
          placeholder="Please enter a customer billing id"
          onChange={onChange}
          value={customer.billing_id}
        />

        <Message error content={error?.content} />
      </FormField>
    </Form>
  );
}

export default CustomerDetails;
