import { Image } from "semantic-ui-react";

function Avatar({ src, floated, ...otherProps }) {
  const imageSrc =
    !src || src.substring(0, 7) === "initial"
      ? `https://eu.ui-avatars.com/api/?name=${
          src ? src.substring(8) : ""
        }&size=200`
      : src;

  return <Image src={imageSrc} floated={floated} {...otherProps} />;
}

export default Avatar;
