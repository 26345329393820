import {
  Button,
  Form,
  Header,
  Message,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "semantic-ui-react";

const fieldLabels = {
  billing_id: "Billing ID / CLI",
  reseller_name: "Reseller name",
  customer_name: "Customer name",
  api_required: "Using API?",
  tenant_prefix: "Tenant prefix",
  ipecs_server_url: "iPECS ONE server URL",
  ipecs_api_key: "iPECS ONE API key",
  ipecs_api_key_secret: "iPECS ONE API secret",
  quantity: "Number of licenses",
  admin_email: "Admin email address",
  admin_extension: "Admin iPECS extension number",
};

const secretFields = ["ipecs_api_key_secret"];

const FieldValue = ({ name, value }) => {
  if (secretFields.includes(name)) {
    return "*****";
  }

  switch (value) {
    case true:
      return <>Yes</>;
    case false:
      return <>No</>;
    default:
      return <>{value ?? "N/A"}</>;
  }
};

const Section = ({ title, section }) => {
  return (
    <>
      <Header as={"h2"}>{title}</Header>
      <Table inverted celled fixed>
        <TableBody>
          {Object.keys(section)
            .filter((k) => (fieldLabels[k] ? true : false))
            .map((k, i) => {
              return (
                <TableRow key={`field-${i}`}>
                  <TableCell>{fieldLabels[k] ?? k}</TableCell>
                  <TableCell>
                    <FieldValue name={k} value={section[k]} />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </>
  );
};

function Confirm({ formState, onSubmit, onClickBack, submitting }) {
  return (
    <>
      <Section title="Customer Details" section={formState.customer} />
      {formState.connect.required && (
        <Section title="Connect Details" section={formState.connect} />
      )}
      {formState.sidekick.required && (
        <Section title="Sidekick Details" section={formState.sidekick} />
      )}
      {formState.whatsapp.required && (
        <Section title="WhatsApp Details" section={formState.whatsapp} />
      )}

      <Message>
        <p>
          Please confirm details above. When you are ready, click submit. You
          will receive an email containing a link to approve this order.
        </p>
        <p>You will need to click this link in order to process this order.</p>
      </Message>

      <Form onSubmit={onSubmit}>
        <Button.Group>
          <Button onClick={onClickBack}>Back</Button>
          <Button.Or />
          <Button loading={submitting} primary>
            Submit
          </Button>
        </Button.Group>
      </Form>
    </>
  );
}

export default Confirm;
