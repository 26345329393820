import Picker from "@emoji-mart/react";
import { Icon, Popup } from "semantic-ui-react";
import useEmojiPicker from "../../hooks/useEmojiPicker";
import { style } from "./style";

const EmojiPicker = ({ setMessage }) => {
  const emojiPicker = useEmojiPicker({ setMessage });

  return (
    <Popup
      content={
        <Picker
          set="native"
          theme="dark"
          onEmojiSelect={emojiPicker.handleSelectEmoji}
          onClickOutside={emojiPicker.handleCloseEmoji}
        />
      }
      on="click"
      open={emojiPicker.emojiOpen}
      onClose={emojiPicker.handleCloseEmoji}
      onOpen={emojiPicker.handleOpenEmoji}
      basic
      position="top right"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0)",
        border: "none",
        boxShadow: "none",
      }}
      trigger={
        <Icon
          name="smile outline"
          size="large"
          style={style.reply.actionButton}
          link
        />
      }
    />
  );
};

export default EmojiPicker;
