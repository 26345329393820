import moment from "moment";

function getFriendlyTimestamp(timestamp) {
  return moment(timestamp).calendar(null, { sameElse: "D/M/YYYY" });
}

function getFormattedTimestamp(timestamp) {
  return moment(timestamp).format("dddd D MMMM YYYY [at] h.mm a");
}

function getShortTimestamp(timestamp) {
  return moment(timestamp).format("D/M/YY");
}

export { getFriendlyTimestamp, getFormattedTimestamp, getShortTimestamp };
