import { sdk } from "@gc/ipecs-web-sdk";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Grid,
  GridColumn,
  GridRow,
  Header,
  Icon,
  Message,
  Segment,
} from "semantic-ui-react";
import { getIpecsLoginOptions, ipecsLogin, logout } from "../api/auth";
import config from "../config";
import { storageKeys } from "../constants";
import { useAuth } from "../contexts/AuthContext";
import LoginFormLogo from "./LoginFormLogo";

const style = {
  form: {
    maxWidth: "500px",
    margin: "auto",
  },
  submit: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  phonemode: {
    marginTop: "3rem",
    marginBottom: "3rem",
  },
  removeDevices: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
};

const DEVICE_ID = "5991fd0-29d1-4f88-99cc-865cf1e2f2fe1";
const DEVICE_NAME = "Connect";
const defaultError = { header: "", message: "" };

const getUserPassLoginParams = (
  url,
  key,
  username,
  password,
  removeDeviceIds,
  phoneControlMode,
) => {
  return {
    deviceId: DEVICE_ID,
    deviceDisplayName: DEVICE_NAME,
    isPhoneControl: phoneControlMode ? true : false,
    callMode: phoneControlMode ? "phone" : "soft",
    removeDeviceIds: removeDeviceIds,
    serverUrl: url,
    customerApplicationApiKey: key,
    username: username,
    password: password,
  };
};

const getTokenLoginParams = (url, token, removeDeviceIds, phoneControlMode) => {
  return {
    deviceId: DEVICE_ID,
    deviceDisplayName: DEVICE_NAME,
    isPhoneControl: phoneControlMode ? true : false,
    callMode: phoneControlMode ? "phone" : "soft",
    removeDeviceIds: removeDeviceIds,
    serverUrl: url,
    authToken: token,
  };
};

function IpecsLoginOptions() {
  const {
    apiUser,
    setApiUser,
    setIpecsAuthenticated,
    setIpecsUser,
    setIpecsAuthData,
    isPhoneControl,
    setIsPhoneControl,
    setTokenReissued,
  } = useAuth();
  const [error, setError] = useState(defaultError);
  const [onACall, setOnACall] = useState(false);
  const [loadedFromStore, setLoadedFromStore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [maxDevices, setMaxDevices] = useState(false);
  const [removeDeviceIds, setRemoveDeviceIds] = useState([]);
  const [devices, setDevices] = useState([]);
  const [tokenReissueFailed, setTokenReissueFailed] = useState(false);
  const { auth, user } = sdk;

  useEffect(() => {
    // iPECS SDK doesn't allow access to response body so we have to some hackery
    XMLHttpRequest.prototype.realSend = XMLHttpRequest.prototype.send;
    XMLHttpRequest.prototype.send = function (value) {
      this.addEventListener(
        "loadend",
        function (e) {
          try {
            setDevices(JSON.parse(e.srcElement.response).errors?.devices ?? []);
          } catch (e) {
            // do nothing
          }
        },
        false,
      );
      this.realSend(value);
    };

    return () =>
      (XMLHttpRequest.prototype.send = XMLHttpRequest.prototype.realSend);
  }, []);

  const loginOptionsQuery = useQuery(
    ["ipecs-login-options"],
    () => {
      return getIpecsLoginOptions();
    },
    { enabled: tokenReissueFailed },
  );

  const handleReissueToken = useCallback(async () => {
    const storedAuthData = JSON.parse(
      localStorage.getItem(storageKeys.IPECS_AUTH),
    );

    if (storedAuthData) {
      try {
        const { accessToken } = await auth.reissueAccessToken(
          {
            deviceDisplayName: "Connect",
            refreshToken: storedAuthData.authInfo.refreshToken,
            serverUrl: apiUser.server.url,
          },
          false,
        );
        const authData = await auth.login({
          deviceId: DEVICE_ID,
          deviceDisplayName: DEVICE_NAME,
          isPhoneControl: isPhoneControl ? true : false,
          callMode: isPhoneControl ? "phone" : "soft",
          removeDeviceIds: [],
          serverUrl: apiUser.server.url,
          customerApplicationApiKey: apiUser.server.key,
          accessToken: accessToken,
          companyId: storedAuthData.authInfo.companyId,
          userId: storedAuthData.authInfo.userId,
          serverName: storedAuthData.authInfo.serverName,
          systemTenantPrefix: storedAuthData.authInfo.systemTenantPrefix,
          tenantPrefix: storedAuthData.authInfo.tenantPrefix,
        });
        ipecsLogin({ at: authData.authInfo.accessToken }).catch(() => {});
        // don't send this to API as auth d ata won't have a refresh token
        // the existing refresh token will work
        setIpecsAuthData(authData);
        setIpecsUser(await user.getMyInfo());
        setSubmitting(false);
        setIpecsAuthenticated(true);
        setLoadedFromStore(true);
        return true;
      } catch (e) {
        console.error(e);
      }
    }

    setTokenReissueFailed(true);
    return false;
  }, [
    auth,
    user,
    apiUser.server,
    isPhoneControl,
    setIpecsUser,
    setIpecsAuthData,
    setIpecsAuthenticated,
  ]);

  const handleRelogin = useCallback(async () => {
    if (!loginOptionsQuery.data?.data?.data) {
      return;
    }

    console.log("attempt relogin");

    const ipecsUser = loginOptionsQuery.data?.data?.data.ipecs_user;

    const details =
      config.customer === "next"
        ? { mode: "AUTH_TOKEN", id: ipecsUser?.id } // this will cause the app to login without prompting user to continue
        : JSON.parse(localStorage.getItem(storageKeys.IPECS_LOGIN));

    if (!details || !apiUser.hasOwnProperty("server")) {
      setLoadedFromStore(true);
      return;
    }

    if (details.hasOwnProperty("mode") && details.mode === "AUTH_TOKEN") {
      // user they logged in is different to what ipecs is returning as their user
      if (!ipecsUser || ipecsUser.id !== details.id) {
        setLoadedFromStore(true);
        return;
      }

      try {
        const authData = await auth.login(
          getTokenLoginParams(
            apiUser.server.url,
            ipecsUser.token,
            [],
            isPhoneControl,
          ),
        );
        ipecsLogin({
          mode: "AUTH_TOKEN",
          phone_control: isPhoneControl,
          id: loginOptionsQuery.data?.data?.data.ipecs_user.id,
          at: authData.authInfo.accessToken,
          rt: authData.authInfo.refreshToken,
          uid: authData.authInfo.wucUserId,
          muid: authData.authInfo.userId,
        }).catch(() => {});
        setIpecsUser(await user.getMyInfo());
        localStorage.setItem(storageKeys.IPECS_AUTH, JSON.stringify(authData));
        setIpecsAuthData(authData);
        setIpecsAuthenticated(true);
      } catch (error) {
        setIpecsAuthenticated(false);
        if (error.message === "E_NOT_ALLOWED_LOGIN_IF_CALL_LEG_EXIST") {
          setOnACall(true);
        }
      }
    } else if (
      details.username !== undefined &&
      details.password !== undefined
    ) {
      try {
        const authData = await auth.login(
          getUserPassLoginParams(
            apiUser.server.url,
            apiUser.server.key,
            details.username,
            details.password,
            [],
            isPhoneControl,
          ),
        );
        ipecsLogin({
          mode: "USER_PASS",
          phone_control: isPhoneControl,
          username: details.username,
          password: details.password,
          at: authData.authInfo.accessToken,
          rt: authData.authInfo.refreshToken,
          uid: authData.authInfo.wucUserId,
          muid: authData.authInfo.userId,
        }).catch(() => {});
        setIpecsUser(await user.getMyInfo());
        setIpecsAuthData(authData);
        setIpecsAuthenticated(true);
      } catch (error) {
        setIpecsAuthenticated(false);
        if (error.message === "E_NOT_ALLOWED_LOGIN_IF_CALL_LEG_EXIST") {
          setOnACall(true);
        }
      }
    }

    setLoadedFromStore(true);
  }, [
    auth,
    user,
    apiUser,
    setIpecsUser,
    setIpecsAuthenticated,
    setIpecsAuthData,
    loginOptionsQuery.data?.data?.data,
    isPhoneControl,
  ]);

  useEffect(() => {
    const handle = async () => {
      if (tokenReissueFailed) {
        await handleRelogin();
        setTokenReissued(false);
      } else {
        const reissued = await handleReissueToken();
        setTokenReissued(reissued);
        console.log(`Reissued: ${reissued}`);
      }
    };

    handle();
  }, [tokenReissueFailed, setTokenReissued, handleReissueToken, handleRelogin]);

  async function handleTokenLogin(id, token) {
    await handleLogin(
      getTokenLoginParams(
        apiUser.server.url,
        token,
        removeDeviceIds,
        isPhoneControl,
      ),
      {
        mode: "AUTH_TOKEN",
        phone_control: isPhoneControl,
        id: id,
      },
      {
        mode: "AUTH_TOKEN",
        id: id,
      },
    );
  }

  async function handleUserPassLogin(username, password) {
    await handleLogin(
      getUserPassLoginParams(
        apiUser.server.url,
        apiUser.server.key,
        username,
        password,
        removeDeviceIds,
        isPhoneControl,
      ),
      {
        mode: "USER_PASS",
        phone_control: isPhoneControl,
        username,
        password,
      },
      {
        mode: "USER_PASS",
        username,
        password,
      },
    );
  }

  const handleLogin = async (ipecsParams, apiParams, storageParams) => {
    setSubmitting(true);

    try {
      const authData = await auth.login(ipecsParams);
      ipecsLogin({
        ...apiParams,
        at: authData.authInfo.accessToken,
        rt: authData.authInfo.refreshToken,
        uid: authData.authInfo.wucUserId,
        muid: authData.authInfo.userId,
      }).catch(() => {});
      localStorage.setItem(
        storageKeys.IPECS_LOGIN,
        JSON.stringify(storageParams),
      );
      localStorage.setItem(storageKeys.IPECS_AUTH, JSON.stringify(authData));
      setIpecsAuthData(authData);
      setIpecsUser(await user.getMyInfo());
      setSubmitting(false);
      setIpecsAuthenticated(true);
    } catch (error) {
      handleLoginError(error);
    }
  };

  const handleLoginError = (error) => {
    console.log(error);
    if (
      error.message === "E_OVER_MAX_DEVICES" ||
      error.message === "E_OVER_MAX_PHONE_CONTROL_DEVICES"
    ) {
      setMaxDevices(true);
    } else if (error.message === "E_NOT_ALLOWED_LOGIN_IF_CALL_LEG_EXIST") {
      setMaxDevices(false);
      setError({
        header: "Already on a call",
        message: "You can not login while already on a call",
      });
    } else if (error.message === "E_NOT_ALLOWED_PHONE_CONTROL_MODE") {
      setError({
        header: "Phone Control Not Allowed",
        message: "iPECS is not configured for phone control mode on this user.",
      });
    } else {
      setMaxDevices(false);
      setError({
        header: "Login Failed",
        message:
          error.message === "E_INVALID_INPUT"
            ? "Invalid username or password"
            : `Error connecting to iPECS`,
      });
    }
    setSubmitting(false);
    setLoading(false);
    setIpecsAuthenticated(false);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    setLoading(true);

    logout()
      .then(() => {
        localStorage.removeItem(storageKeys.IPECS_LOGIN);
        setApiUser();
      })
      .catch(() => {
        setLoading(false);
        alert("Logout failed");
      });
  };

  if (!loadedFromStore || loading || loginOptionsQuery.isFetching) return null;

  if (maxDevices)
    return (
      <>
        <LoginFormLogo />
        <MaxDevices
          devices={devices}
          setRemoveDeviceIds={(ids) => {
            setRemoveDeviceIds(ids);
            setMaxDevices(false);
          }}
        />
      </>
    );

  if (onACall) {
    return (
      <>
        <LoginFormLogo />
        <div>
          <div style={style.form}>
            <Message negative>
              <Message.Header>You are already in a call</Message.Header>
              <p>
                You are currently in a call preventing iPECS from logging in.
                <br />
                Once you have finished your call, refresh this page to login.
              </p>
            </Message>

            <Button
              fluid
              basic
              color="grey"
              disabled={loading || submitting}
              onClick={handleLogout}
            >
              Logout
            </Button>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <LoginFormLogo />
      <div style={style.form}>
        {config.customer === "next" ? (
          <Header style={{ textAlign: "center" }}>
            Failed to find or connect to your iPECS user
          </Header>
        ) : (
          <>
            <Header>{apiUser.server.name} iPECS Login</Header>

            {loginOptionsQuery.data?.data?.data.ipecs_user ? (
              <>
                <div style={{ marginBottom: "12px" }}>
                  <IpecsUserSelection
                    ipecsUser={loginOptionsQuery.data?.data?.data.ipecs_user}
                    onClick={() =>
                      handleTokenLogin(
                        loginOptionsQuery.data?.data?.data.ipecs_user.id,
                        loginOptionsQuery.data?.data?.data.ipecs_user.token,
                        [],
                      )
                    }
                    loading={loading}
                  />
                </div>

                <Button
                  style={{ marginBottom: "12px" }}
                  fluid
                  basic
                  color="grey"
                  onClick={() => setShowLoginForm(!showLoginForm)}
                >
                  Alternatively, click here to manually enter your login
                  details.
                </Button>

                {showLoginForm && (
                  <ManualLoginForm
                    loading={submitting}
                    handleLogin={handleUserPassLogin}
                  />
                )}
              </>
            ) : (
              <>
                <p>Enter your iPECS details below.</p>
                <ManualLoginForm
                  loading={submitting}
                  handleLogin={handleUserPassLogin}
                />
              </>
            )}
            <Button
              fluid
              basic
              color="grey"
              disabled={loading || submitting}
              onClick={handleLogout}
            >
              Logout
            </Button>

            <Segment basic style={style.phonemode}>
              <Grid columns={2} stackable textAlign="center">
                <Divider vertical>Or</Divider>

                <GridRow verticalAlign="middle">
                  <GridColumn>
                    <Header>Softphone</Header>
                    <p>Use the built-in Connect softphone.</p>
                    <Button
                      active={!isPhoneControl}
                      basic={isPhoneControl}
                      color={!isPhoneControl ? "blue" : "grey"}
                      disabled={loading || submitting}
                      onClick={() => setIsPhoneControl(false)}
                    >
                      {!isPhoneControl ? "Selected" : "Switch"}
                    </Button>
                  </GridColumn>

                  <GridColumn>
                    <Header>Phone Control</Header>
                    <p>Use your linked iPECS desk phone.</p>
                    <Button
                      active={isPhoneControl}
                      basic={!isPhoneControl}
                      color={isPhoneControl ? "blue" : "grey"}
                      disabled={loading || submitting}
                      onClick={() => setIsPhoneControl(true)}
                    >
                      {isPhoneControl ? "Selected" : "Switch"}
                    </Button>
                  </GridColumn>
                </GridRow>
              </Grid>
            </Segment>

            {error.header.length > 0 && (
              <Message negative>
                <Message.Header>{error.header}</Message.Header>
                <p>{error.message}</p>
              </Message>
            )}
          </>
        )}
      </div>
    </>
  );
}

function ManualLoginForm({ loading, handleLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(defaultError);
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <Form style={style.form} onSubmit={() => handleLogin(username, password)}>
      <Form.Field>
        <label>Username</label>
        <input
          placeholder="Username"
          value={username}
          onChange={(e) => {
            setError(defaultError);
            setUsername(e.target.value.trim());
          }}
        />
      </Form.Field>

      <Form.Field>
        <label>Password</label>
        <Form.Input iconPosition="right">
          <Icon
            name={passwordVisible ? "eye slash" : "eye"}
            link
            onClick={() => setPasswordVisible(!passwordVisible)}
          />
          <input
            placeholder="Password"
            type={passwordVisible ? "text" : "password"}
            value={password}
            onChange={(e) => {
              setError(defaultError);
              setPassword(e.target.value.trim());
            }}
          />
        </Form.Input>
      </Form.Field>

      <Button
        fluid
        style={style.submit}
        type="submit"
        loading={loading}
        disabled={loading}
        primary
      >
        Continue
      </Button>

      {error.header.length > 0 && (
        <Message negative>
          <Message.Header>{error.header}</Message.Header>
          <p>{error.message}</p>
        </Message>
      )}
    </Form>
  );
}

function IpecsUserSelection({ ipecsUser, onClick, loading }) {
  return (
    <div>
      <p>
        We've found the below iPECS user linked to your email. Instantly login
        by clicking the below button.
      </p>
      <IpecsUserButton user={ipecsUser} onClick={onClick} loading={loading} />
    </div>
  );
}

function IpecsUserButton({ user, ...props }) {
  const [hover, setHover] = useState(false);

  return (
    <Button
      fluid
      animated="fade"
      color={hover ? "teal" : "blue"}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props}
    >
      <Button.Content visible>Continue as {user.display_name}</Button.Content>
      <Button.Content hidden>Confirm Login</Button.Content>
    </Button>
  );
}

function MaxDevices({ devices, setRemoveDeviceIds }) {
  return (
    <Form style={style.form}>
      <Message negative>
        <Message.Header>Max devices reached</Message.Header>
        <p>
          You have reached the maximum number of devices you can login with.
          Click one of the below to unlink it from your user, you will then need
          to login again.
        </p>
      </Message>

      <div style={style.removeDevices}>
        {devices
          .filter((x) => x.deviceId !== "5d9c75586a29d860657137a9a0989330")
          .map((x) => (
            <RemoveDeviceButton
              key={x.id}
              device={x}
              setRemoveDeviceIds={setRemoveDeviceIds}
            />
          ))}
      </div>
    </Form>
  );
}

function RemoveDeviceButton({ device, setRemoveDeviceIds }) {
  const [hover, setHover] = useState(false);

  return (
    <Button
      animated="fade"
      color={hover ? "red" : "blue"}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => setRemoveDeviceIds([device.deviceId])}
    >
      <Button.Content visible>{device.deviceDisplayName}</Button.Content>
      <Button.Content hidden>Remove device</Button.Content>
    </Button>
  );
}

export default IpecsLoginOptions;
