import { motion } from "framer-motion";
import React from "react";
import Lottie from "react-lottie";
import { Segment } from "semantic-ui-react";
import connectingAnimation from "../../animations/connecting.json";
import ContactDisplayName from "../ContactDisplayName";

const style = {
  infoText: {
    position: "absolute",
    top: 0,
    display: "flex",
    alignItems: "center",
    height: "calc(100% - 100px)",
    zIndex: 1,
  },
};

function ActiveCallConnecting({ callParty, minimized, invalid }) {
  return (
    <div style={style.infoText}>
      <Segment basic textAlign="center">
        {invalid ? (
          <>This number is invalid</>
        ) : (
          <>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: connectingAnimation,
              }}
              height={minimized ? 60 : 400}
              width={minimized ? 60 : 400}
            />
            <ContactDisplayName
              name={callParty?.name}
              number={callParty?.callNumber}
            />
            <TextAnimation>Connecting</TextAnimation>
          </>
        )}
      </Segment>
    </div>
  );
}

function TextAnimation({ children }) {
  return (
    <motion.p
      style={{ marginTop: "20px" }}
      animate={{
        opacity: [1, 1, 0.5, 1, 1],
      }}
      transition={{
        duration: 5,
        ease: "easeInOut",
        times: [0, 0.4, 0.6, 0.8, 1],
        repeat: Infinity,
      }}
    >
      {children}
    </motion.p>
  );
}

export default ActiveCallConnecting;
