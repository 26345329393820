import Lottie from "react-lottie";
import { Container, Segment } from "semantic-ui-react";
import ahromeAnimation from "../animations/chrome.json";
import logo from "../logos/white.svg";

const style = {
  container: {
    paddingTop: "6rem",
    padding: "3rem",
    textAlign: "center",
    display: "flex",
    flexDirection: " column",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
  },
  logo: {
    width: "240px",
  },
};

function NeedChrome() {
  return (
    <Container>
      <Segment basic style={style.container}>
        <div style={style.logo}>
          <img src={logo} alt="Logo" />
        </div>
        <div style={{ fontSize: "1.1rem" }}>
          For full functionality you should use a Chrome based browser
        </div>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: ahromeAnimation,
          }}
          height={80}
          width={80}
        />
      </Segment>
    </Container>
  );
}

export default NeedChrome;
