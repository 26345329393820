import { useState } from "react";
import { Button, Form, Header, Message } from "semantic-ui-react";
import { licenseKeyLookup, logout } from "../api/auth";
import { useAuth } from "../contexts/AuthContext";
import LoginFormLogo from "./LoginFormLogo";

const style = {
  form: {
    maxWidth: "460px",
    margin: "auto",
  },
  submit: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
};

const defaultError = { header: "", message: "" };

function LicenseKeyPrompt() {
  const { setApiUser } = useAuth();
  const [error, setError] = useState(defaultError);
  const [loading, setLoading] = useState(false);
  const [licenseKey, setLicenseKey] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    licenseKeyLookup(licenseKey)
      .then((r) => setApiUser(r.data.data))
      .catch((error) => {
        setError({
          header: "Invalid Licence Key",
          message: "Licence key does not exist or has already been used.",
        });
      })
      .finally(() => setLoading(false));
  };

  const handleLogout = (e) => {
    e.preventDefault();
    setLoading(true);
    logout()
      .then(() => setApiUser())
      .catch(() => {
        setLoading(false);
        alert("Logout failed");
      });
  };

  return (
    <>
      <LoginFormLogo />
      <Form style={style.form} onSubmit={handleSubmit}>
        <Header>Enter your licence key</Header>

        <Form.Field>
          <input
            placeholder="XXX-XXX-XXX-XXX"
            value={licenseKey}
            onChange={(e) => {
              setError(defaultError);
              setLicenseKey(e.target.value.trim());
            }}
          />
        </Form.Field>

        <Button
          style={style.submit}
          type="submit"
          loading={loading}
          disabled={loading}
          primary
        >
          Continue
        </Button>

        <Button
          style={style.submit}
          loading={loading}
          disabled={loading}
          onClick={handleLogout}
          secondary
        >
          Logout
        </Button>

        {error.header.length > 0 && (
          <Message negative>
            <Message.Header>{error.header}</Message.Header>
            <p>{error.message}</p>
          </Message>
        )}
      </Form>
    </>
  );
}

export default LicenseKeyPrompt;
