import { parsePhoneNumber } from "libphonenumber-js";

export const convertToE164 = (number, phoneLocality) => {
  try {
    // if the number starts with a zero, it's GB
    // otherwise, add a + to make it e164
    if (!number.startsWith("0") && !number.startsWith("+")) {
      number = "+" + number;
    }

    const parsedNumber = parsePhoneNumber(number, phoneLocality ?? "GB");
    return parsedNumber.number;
  } catch (error) {
    return number;
  }
};
