import React, { useEffect, useRef, useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import ringtone from "../../audio/ringtone.mp3";
import { useDevices } from "../../contexts/DevicesContext";
import { useSoftphone } from "../../contexts/SoftphoneContext";
import IncomingCallAlert, { Popup } from "../activeCall/IncomingCallAlert";

function IncomingSoftphoneCallAlert() {
  const {
    incomingCall,
    handleAnswerIncoming,
    hndleVmTransferIncoming,
    handleRejectIncoming,
    incomingCallPartyInfo,
  } = useSoftphone();
  const { ringOutput } = useDevices();

  const [incomingAnswering, setIncomingAnswering] = useState(false);
  const [vmTransferring, setVmTransferring] = useState(false);
  const [incomingRejecting, setIncomingRejecting] = useState(false);

  const [answerType, setAnswerType] = useState();

  const ringtoneRef = useRef();

  const handleAnswerWithAudio = () => {
    setIncomingAnswering(true);
    setAnswerType("audio");
    handleAnswerIncoming("audio")
      .then(() => setIncomingAnswering(false))
      .catch(() => setIncomingAnswering(false));
  };

  const handleAnswerWithVideo = () => {
    setIncomingAnswering(true);
    setAnswerType("video");
    handleAnswerIncoming("video")
      .then(() => setIncomingAnswering(false))
      .catch(() => setIncomingAnswering(false));
  };

  const handleVmTransfer = () => {
    setVmTransferring(true);
    hndleVmTransferIncoming()
      .then(() => setVmTransferring(false))
      .catch(() => setVmTransferring(false));
  };

  const handleReject = () => {
    setIncomingRejecting(true);
    handleRejectIncoming()
      .then(() => setIncomingRejecting(false))
      .catch(() => setIncomingRejecting(false));
  };

  useEffect(() => {
    ringtoneRef.current.setSinkId(ringOutput || "default");
  }, [ringOutput]);

  return (
    <>
      <audio ref={ringtoneRef} src={ringtone} autoPlay loop></audio>
      <IncomingCallAlert
        name={incomingCallPartyInfo?.name ?? incomingCall.roomName}
        number={incomingCall.callNumber}
      >
        <div className="ui four buttons">
          {incomingCall?.mediaType === "video" && (
            <Button
              color="purple"
              onClick={handleAnswerWithVideo}
              loading={incomingAnswering && answerType === "video"}
              disabled={
                incomingAnswering || vmTransferring || incomingRejecting
              }
              icon
            >
              <Icon name="video" />
            </Button>
          )}
          <Popup
            content="Answer"
            trigger={
              <Button
                color="green"
                onClick={handleAnswerWithAudio}
                loading={incomingAnswering && answerType === "audio"}
                disabled={
                  incomingAnswering || vmTransferring || incomingRejecting
                }
                icon
              >
                <Icon name="phone" />
              </Button>
            }
          />

          <Popup
            content="Send to voicemail"
            trigger={
              <Button
                color="yellow"
                onClick={handleVmTransfer}
                loading={vmTransferring}
                disabled={
                  incomingAnswering || vmTransferring || incomingRejecting
                }
              >
                <Icon name="envelope outline" />
              </Button>
            }
          />

          <Popup
            content="Reject"
            trigger={
              <Button
                color="red"
                onClick={handleReject}
                loading={incomingRejecting}
                disabled={incomingAnswering || incomingRejecting}
              >
                <Icon name="user close" />
              </Button>
            }
          />
        </div>
      </IncomingCallAlert>
    </>
  );
}

export default IncomingSoftphoneCallAlert;
