import { useQuery } from "@tanstack/react-query";
import moment from "moment-timezone";
import React from "react";
import { getCalendarEvents } from "../api/calendar";
import CalendarUpcomingEvents from "../components/CalendarUpcomingEvents";
import CalendarWeekView from "../components/CalendarWeekView";
import PageLoader from "../components/PageLoader";
import SideScrollerLayout, {
  SideScrollerContent,
  SideScrollerSidebar,
  SideScrollerSidebarItem,
} from "../components/SideScrollerLayout";

function Calendar() {
  const { isFetching, data } = useQuery({
    queryKey: ["calendar-events"],
    queryFn: async () => {
      const res = await getCalendarEvents();

      const _week = res.data.data.week.map((i) => {
        i.start = moment(i.start.human, "DD/MM/YYYY HH:mm");
        i.end = moment(i.end.human, "DD/MM/YYYY HH:mm");
        return i;
      });

      const _upcoming = res.data.data.upcoming.map((i) => {
        i.start = moment(i.start.human, "DD/MM/YYYY HH:mm");
        i.end = moment(i.end.human, "DD/MM/YYYY HH:mm");
        return i;
      });

      return { week: _week, upcoming: _upcoming };
    },
    keepPreviousData: true,
    cacheTime: 3600000, // one hour
    staleTime: 60000, // 1 mins
  });

  if (isFetching) return <PageLoader>Calendar loading...</PageLoader>;

  return (
    <SideScrollerLayout>
      <SideScrollerSidebar>
        <SideScrollerSidebarItem>
          <h3>Upcoming Events</h3>
        </SideScrollerSidebarItem>

        <CalendarUpcomingEvents calendarEvents={data.upcoming} />
      </SideScrollerSidebar>

      <SideScrollerContent>
        <CalendarWeekView calendarEvents={data.week} />
      </SideScrollerContent>
    </SideScrollerLayout>
  );
}

export default Calendar;
