import { Menu } from "semantic-ui-react";
import styled from "styled-components";
import layout from "../layout";
import devices from "../layout/devices";

const leftBg = "#222333";

const Left = styled.div`
  min-width: 300px;
  width: 300px;
  height: 100%;
  background-color: ${leftBg};
  display: none;

  @media ${devices.tablet} {
    display: block;
  }
`;

const style = {
  wrapper: {
    display: "flex",
    height: `calc(100vh - ${layout.topbarHeight})`,
  },
  menu: {
    height: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
    backgroundColor: leftBg,
  },
};

function SideScrollerLayout({ children }) {
  return <div style={style.wrapper}>{children}</div>;
}

function SideScrollerSidebar({ children }) {
  return (
    <Left>
      <div style={style.menu}>
        <Menu vertical inverted style={{ border: "none", width: "100%" }}>
          {children}
        </Menu>
      </div>
    </Left>
  );
}

function SideScrollerSidebarItem({ children, ...otherProps }) {
  return <Menu.Item {...otherProps}>{children}</Menu.Item>;
}

function SideScrollerContent({ children }) {
  return children;
}

export { SideScrollerContent, SideScrollerSidebar, SideScrollerSidebarItem };
export default SideScrollerLayout;
