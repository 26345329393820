import { useState } from "react";
import { Divider, Icon, Image } from "semantic-ui-react";
import ImageModal from "../ImageModal";

function EventMessage({ text }) {
  return <Divider horizontal>{text}</Divider>;
}

function FileMessage({ message, downloadUrl, fileName, byMe }) {
  return (
    <a
      href={downloadUrl}
      style={{
        color: byMe ? "#1b1b27" : "#fff",
        decoration: "underline",
      }}
      target="_blank"
      rel="noreferrer"
    >
      <Icon name="file alternate" color="blue" size="big" />
      {fileName || message}{" "}
      <Icon
        name="cloud download"
        color="black"
        inverted={byMe ? false : true}
        size="small"
      />
    </a>
  );
}

function ImageMessage({ message, downloadUrl, isGif = false, caption }) {
  const [imageModalOpen, setImageModalOpen] = useState(false);
  let imageUrl = downloadUrl;

  if (isGif) {
    const regexp = /(\[gif:)(http.*)(])/;
    const matches = message.match(regexp);
    imageUrl = matches[2];
  }

  return (
    <span>
      <Image
        src={imageUrl}
        alt={message.value}
        loading="lazy"
        size="medium"
        rounded
        onClick={() => setImageModalOpen(true)}
        style={{ cursor: "pointer" }}
      />
      {caption && <div style={{ marginTop: "5px" }}>{caption}</div>}
      {imageModalOpen && (
        <ImageModal
          imageUrl={imageUrl}
          caption={caption}
          setImageModalOpen={setImageModalOpen}
          imageModalOpen={imageModalOpen}
        />
      )}
    </span>
  );
}

function TextMessage({ message }) {
  const regexp = /^(\p{Emoji_Presentation}){1,3}$/u;
  const isEmojiMessage = regexp.test(message);

  if (isEmojiMessage) {
    return (
      <span style={{ fontSize: "2.75em", lineHeight: "1.5em" }}>{message}</span>
    );
  } else {
    return message;
  }
}

export { EventMessage, FileMessage, ImageMessage, TextMessage };
