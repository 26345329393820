import axios from "axios";
import config from "../config";

export function getEngageChats(filters = {}) {
  return axios.get(`${config.api_url}/engage/chats`, {
    params: filters,
  });
}

export function getEngageMessages(chatId, filters = {}) {
  return axios.get(`${config.api_url}/engage/messages/${chatId}`, {
    params: filters,
  });
}

export function sendEngageMessage(chatId, message) {
  return axios.post(`${config.api_url}/engage/messages/${chatId}`, {
    message: message,
  });
}
