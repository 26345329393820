import { useEffect } from "react";
import { toInteger } from "lodash";

import { sdk } from "@gc/ipecs-web-sdk";
import { Dimmer, Icon, Loader, Menu, Popup, Segment } from "semantic-ui-react";
import ContactDisplayName from "./ContactDisplayName";
import { SideScrollerSidebarItem } from "./SideScrollerLayout";

import { useAuth } from "../contexts/AuthContext";
import { useCalls } from "../contexts/CallsContext";
import { useCallLogs } from "../contexts/CallLogsContext";

function CallLogs() {
  const { hasActiveCall, handleMakeCall } = useCalls();
  const { callLogs, setCallLogs, setMissedCallCount } = useCallLogs();
  const { ipecsUser } = useAuth();
  const { callLog } = sdk;

  useEffect(() => {
    callLog.getCallLogs().then((res) => setCallLogs(res.callLogs));
  }, [callLog, setCallLogs]);

  useEffect(() => {
    setMissedCallCount(0);
    callLog.confirmCallLogs();
  }, [callLog, setMissedCallCount]);

  if (callLogs === null) {
    return (
      <Segment basic style={{ height: "200px" }}>
        <Dimmer active style={{ backgroundColor: "transparent" }}>
          <Loader />
        </Dimmer>
      </Segment>
    );
  }

  const getCallLogName = (x) => {
    return /^[0-9]*$/.test(x.counterpart)
      ? {
          ...x,
          counterpartName: (
            <ContactDisplayName
              name={x.counterpartName}
              number={x.counterpartNumber}
            />
          ),
        }
      : x;
  };

  return (
    <>
      {callLogs
        .map(getCallLogName)
        .filter((x) => x.counterpart !== ipecsUser.myInfo.id)
        .map((x) => (
          <SideScrollerSidebarItem key={x.id}>
            <Menu.Header>{x.counterpartName}</Menu.Header>
            <Popup
              position="right center"
              content={
                ["confirmed", "missed", "incoming"].includes(x.type)
                  ? "Incoming"
                  : "Outgoing"
              }
              trigger={
                <Icon
                  color={
                    ["missed", "confirmed"].includes(x.type) ? "red" : "green"
                  }
                  name={
                    ["missed", "confirmed", "incoming"].includes(x.type)
                      ? "reply"
                      : "mail forward"
                  }
                />
              }
            />

            {new Date(toInteger(x.timestamp)).toLocaleString("en-GB", {
              dateStyle: "short",
              timeStyle: "short",
            })}

            {!hasActiveCall() && x.counterpartName !== "Anonymous User" && (
              <Popup
                position="right center"
                content="Call back"
                trigger={
                  <Icon
                    style={{ cursor: "pointer" }}
                    color="blue"
                    name="phone"
                    onClick={() => handleMakeCall(x.counterpartNumber, "audio")}
                  />
                }
              />
            )}
          </SideScrollerSidebarItem>
        ))}
    </>
  );
}

export default CallLogs;
