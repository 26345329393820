import axios from "axios";
import config from "../config";

export function getCalendarEvents() {
  return axios.get(`${config.api_url}/calendar/events`);
}

export function getCalendarPresence() {
  return axios.get(`${config.api_url}/calendar/presence`);
}
