import React, { useState } from "react";
import { Button, Icon, Label, Menu } from "semantic-ui-react";
import { SideScrollerSidebarItem } from "../components/SideScrollerLayout";

function CalendarUpcomingEvents({ calendarEvents }) {
  return (
    <>
      {calendarEvents.length === 0 && (
        <SideScrollerSidebarItem>
          <em>No upcoming events</em>
        </SideScrollerSidebarItem>
      )}

      {calendarEvents.map((event, index) => (
        <SideScrollerSidebarItem key={`event-${index}`}>
          <UpcomingEvent event={event} />
        </SideScrollerSidebarItem>
      ))}
    </>
  );
}

function UpcomingEvent({ event }) {
  const [showAttendees, setShowAttendees] = useState(false);

  const getAttendeeColor = (status) => {
    switch (status) {
      case "organizer":
        return "green";
      case "none":
        return "grey";
      case "tentativelyAccepted":
        return "blue";
      case "accepted":
        return "green";
      case "declined":
        return "red";
      case "notResponded":
        return "grey";
      default:
        return "grey";
    }
  };

  const getAttendeeResponse = (response) => {
    switch (response) {
      case "organizer":
        return "Organiser";
      case "none":
        return "Not Responded";
      case "tentativelyAccepted":
        return "Tentatively Accepted";
      case "accepted":
        return "Accepted";
      case "declined":
        return "Declined";
      case "notResponded":
        return "Not Responded";
      default:
        return "";
    }
  };

  return (
    <div>
      <Menu.Header>
        <Icon name={event.provider} />
        {event.subject}
      </Menu.Header>

      <div style={{ marginTop: "8px" }}>
        {event.start.format("ddd (Do) HH:mm")} -{" "}
        {event.end.format("ddd (Do) HH:mm")}
      </div>

      <div style={{ marginTop: "8px" }}>
        <Button
          basic
          primary
          size="mini"
          onClick={() => setShowAttendees(!showAttendees)}
          style={{ minWidth: "130px" }}
        >
          <Icon name="users" />{" "}
          {showAttendees ? "Hide Attendees" : "Show Attendees"}
        </Button>
        <a href={event.web_link} target="_blank" rel="noreferrer">
          <Button basic primary size="mini" onClick={() => {}}>
            <Icon name="linkify" /> More Details
          </Button>
        </a>
      </div>

      <div style={{ marginTop: "8px" }}>
        {showAttendees && (
          <div>
            <Label
              key={event.organiser.email ?? event.organiser.name}
              style={{ marginBottom: "4px" }}
              color="purple"
            >
              {event.organiser.email ?? event.organiser.name}
              <Label.Detail>Organiser</Label.Detail>
            </Label>

            {event.attendees.map((attendee) => (
              <Label
                key={attendee.email ?? attendee.name}
                style={{ marginBottom: "4px" }}
                color={getAttendeeColor(attendee.response)}
              >
                {attendee.name ?? attendee.email}
                <Label.Detail>
                  {getAttendeeResponse(attendee.response)}
                </Label.Detail>
              </Label>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default CalendarUpcomingEvents;
