import { Checkbox } from "semantic-ui-react";

function YesNoToggle({ value, onChange }) {
  return (
    <div style={{ display: "flex", gap: "1em", margin: "1em 0" }}>
      <label>No</label>
      <Checkbox
        toggle
        name="required"
        checked={value}
        value={value}
        onChange={(e, f) => {
          onChange(e, { name: f.name, value: f.checked });
        }}
      />
      <label>Yes</label>
    </div>
  );
}

export default YesNoToggle;
