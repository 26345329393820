import Lottie from "react-lottie";
import { Container, Grid, Header } from "semantic-ui-react";
import approvedAnimation from "../animations/approved.json";
import config from "../config";

function ApproveOrder() {
  return (
    <Container>
      <Grid
        textAlign="center"
        verticalAlign="middle"
        style={{ height: "100vh" }}
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header textAlign="center">Your order has been approved</Header>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: approvedAnimation,
              speed: 0.5,
            }}
            height={360}
            width={360}
            eventListeners={[
              {
                eventName: "complete",
                callback: async () => {
                  await new Promise((r) => setTimeout(r, 2000));
                  window.location = config.app_url;
                },
              },
            ]}
          />
        </Grid.Column>
      </Grid>
    </Container>
  );
}

export default ApproveOrder;
