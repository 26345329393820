import { motion } from "framer-motion";
import React from "react";
import { Card, Popup as SUIPopup } from "semantic-ui-react";
import ContactDisplayName from "../ContactDisplayName";

const style = {
  incomingAlert: {
    top: 0,
    right: 0,
    position: "fixed",
    zIndex: 99999,
  },
};

function IncomingCallAlert({ name, number, children }) {
  return (
    <motion.div
      style={style.incomingAlert}
      initial={{ y: -300 }}
      animate={{ y: 0 }}
      transition={{
        type: "spring",
        stiffness: 100,
        damping: 20,
      }}
    >
      <Card>
        <Card.Content>
          <Card.Header>
            <ContactDisplayName name={name} number={number} />
          </Card.Header>
          <Card.Meta>Incoming call</Card.Meta>
        </Card.Content>
        <Card.Content extra>{children}</Card.Content>
      </Card>
    </motion.div>
  );
}

function Popup({ ...props }) {
  return (
    <SUIPopup style={{ zIndex: 999999 }} position="bottom center" {...props} />
  );
}

export { Popup };
export default IncomingCallAlert;
