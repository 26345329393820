import { Container, Dimmer, Loader, Segment } from "semantic-ui-react";

function PageLoader({ children, ...otherProps }) {
  return (
    <Container>
      <Segment basic style={{ height: "200px" }}>
        <Dimmer active style={{ backgroundColor: "transparent" }}>
          <Loader {...otherProps}>{children}</Loader>
        </Dimmer>
      </Segment>
    </Container>
  );
}

export default PageLoader;
