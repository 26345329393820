import { useState } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Form,
  FormField,
  FormGroup,
  Icon,
  Input,
  Label,
} from "semantic-ui-react";
import DatePicker from "../components/datePicker/DatePicker";
import CallInfoModal from "../components/sidekick/CallInfoModal";
import CallRecordings from "../components/sidekick/CallRecordings";
import CallTranscriptions from "../components/sidekick/CallTranscriptions";
import sidekickLogo from "../images/sidekick-logo-75x79.png";

export const style = {
  gradientButton: {
    color: "white",
    background:
      "transparent linear-gradient(140deg, #98A0D9 0%, #58B9B6 100%) 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #D2D3EC",
  },
  gradientButtonInactive: {
    color: "white",
    background: "transparent ",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #D2D3EC",
  },
  purpleGradientButton: {
    color: "white",
    background:
      "transparent linear-gradient(128deg, #98A0D9 0%, #CA98D9 100%) 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    border: "1px solid #D2D3EC",
  },
  modalSummaryButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "8px 21px",
    width: "110px",
    justifyContent: "space-around",
  },
};

function Sidekick() {
  const [currentTab, setCurrentTab] = useState("search");
  const [infoModal, setInfoModal] = useState({
    open: false,
    id: "",
    tab: "summary",
  });

  const [searchCriteria, setSearchCriteria] = useState({
    tel_number: "",
    from: "",
    to: "",
  });
  const [searchNumber, setSearchNumber] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchCriteria({
      tel_number: searchNumber,
      from: startDate,
      to: endDate,
    });
  };

  const resetSearch = () => {
    setSearchCriteria({ from: "", tel_number: "", to: "" });
    setSearchNumber("");
    setStartDate("");
    setEndDate("");
  };

  return (
    <Container
      style={{
        margin: "20px 0px",
        display: "flex",
        gap: "15px",
        flexDirection: "column",
      }}
    >
      <div>
        <ButtonGroup>
          <Button
            active={currentTab === "search"}
            onClick={() => setCurrentTab("search")}
            style={
              currentTab === "search"
                ? style.gradientButton
                : style.gradientButtonInactive
            }
          >
            Search
          </Button>
          <Button
            onClick={() => setCurrentTab("processed")}
            style={
              currentTab === "processed"
                ? style.purpleGradientButton
                : style.gradientButtonInactive
            }
          >
            Processed
          </Button>
        </ButtonGroup>
      </div>
      {currentTab === "search" ? (
        <>
          <div>
            <Form>
              <FormGroup inline>
                <FormField>
                  <Input
                    placeholder="Search by number"
                    value={searchNumber}
                    name="tel_number"
                    onChange={(e) => setSearchNumber(e.target.value)}
                    labelPosition="left"
                  >
                    <Label
                      image
                      style={{
                        paddingRight: "0",
                        background: "none",
                        borderTop: "1px solid #767676",
                        borderLeft: "1px solid #767676",
                        borderBottom: "1px solid #767676",
                      }}
                    >
                      <img
                        src={sidekickLogo}
                        alt="Sidekick logo"
                        style={{ padding: "4px 0px 4px 10px" }}
                      />
                    </Label>
                    <input
                      style={{
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderLeft: "none",
                      }}
                    />
                  </Input>
                </FormField>
                <FormField>
                  <DatePicker
                    placeholderText="Start time"
                    dateFormat="dd/MM/yyyy HH:mm"
                    selected={startDate}
                    showTimeSelect
                    onChange={(date) => setStartDate(date)}
                  />
                </FormField>
                <FormField>
                  <DatePicker
                    placeholderText="End time"
                    selected={endDate}
                    dateFormat="dd/MM/yyyy HH:mm"
                    showTimeSelect
                    onChange={(date) => setEndDate(date)}
                  />
                </FormField>
                <ButtonGroup icon>
                  <Button
                    type="submit"
                    style={style.gradientButton}
                    onClick={handleSearch}
                    loading={isSearching}
                  >
                    <Icon
                      name="search"
                      style={{
                        color: "white",
                        visibility: isSearching ? "hidden" : "visible",
                      }}
                    />
                  </Button>
                  {(searchNumber || startDate || endDate) && (
                    <Button
                      color="grey"
                      style={{
                        boxShadow: style.gradientButton.boxShadow,
                        border: style.gradientButton.border,
                      }}
                      onClick={resetSearch}
                    >
                      <Icon name="close" />
                    </Button>
                  )}
                </ButtonGroup>
              </FormGroup>
            </Form>
          </div>

          <CallRecordings
            infoModal={infoModal}
            setInfoModal={setInfoModal}
            searchParams={searchCriteria}
            setIsSearching={setIsSearching}
          >
            <CallInfoModal infoModal={infoModal} setInfoModal={setInfoModal} />
          </CallRecordings>
        </>
      ) : (
        <CallTranscriptions infoModal={infoModal} setInfoModal={setInfoModal}>
          <CallInfoModal infoModal={infoModal} setInfoModal={setInfoModal} />
        </CallTranscriptions>
      )}
    </Container>
  );
}

export default Sidekick;
