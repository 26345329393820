import React, { useEffect, useState } from "react";
import { SiAsana, SiClickup } from "react-icons/si";
import { Accordion, Button, Card, Header, Icon, Menu } from "semantic-ui-react";
import { getTasks } from "../api/tasks";
import PageLoader from "../components/PageLoader";
import SideScrollerLayout, {
  SideScrollerContent,
  SideScrollerSidebar,
  SideScrollerSidebarItem,
} from "../components/SideScrollerLayout";
import layout from "../layout";

const style = {
  mainContent: {
    height: `calc(100vh - ${layout.topbarHeight})`,
    width: "100%",
    overflow: "scroll",
    padding: "24px",
  },
};

function Projects() {
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [activeIndexes, setActiveIndexes] = useState([0, 1, 2, 3]);

  useEffect(() => {
    getTasks()
      .then((res) => setTasks(res.data.data))
      .finally(() => setLoading(false));
  }, []);

  const handleClick = (_, titleProps) => {
    const { index } = titleProps;
    const newActiveIndexes = Array.from(activeIndexes);

    const indexInArray = activeIndexes.indexOf(index);
    if (indexInArray !== -1) {
      newActiveIndexes.splice(indexInArray, 1);
    } else {
      newActiveIndexes.push(index);
    }

    setActiveIndexes(newActiveIndexes);
  };

  if (loading) return <PageLoader>Projects loading...</PageLoader>;

  return (
    <SideScrollerLayout>
      <SideScrollerSidebar>
        <SideScrollerSidebarItem>
          <h3>Upcoming Tasks</h3>
        </SideScrollerSidebarItem>

        {tasks.upcoming_count === 0 ? (
          <SideScrollerSidebarItem>
            <em>No upcoming tasks</em>
          </SideScrollerSidebarItem>
        ) : (
          <>
            {tasks.overdue.map((task) => (
              <UpcomingTask key={task.id} task={task} />
            ))}

            {tasks.today.map((task) => (
              <UpcomingTask key={task.id} task={task} />
            ))}

            {tasks.future.map((task) => (
              <UpcomingTask key={task.id} task={task} />
            ))}
          </>
        )}
      </SideScrollerSidebar>

      <SideScrollerContent>
        <div style={style.mainContent}>
          <Accordion fluid>
            <AccordionItem
              index={0}
              active={activeIndexes.includes(0)}
              title="Overdue"
              tasks={tasks.overdue}
              onClick={handleClick}
            />

            <AccordionItem
              index={1}
              active={activeIndexes.includes(1)}
              title="Today"
              tasks={tasks.today}
              onClick={handleClick}
            />

            <AccordionItem
              index={2}
              active={activeIndexes.includes(2)}
              title="Future"
              tasks={tasks.future}
              onClick={handleClick}
            />

            <AccordionItem
              index={3}
              active={activeIndexes.includes(3)}
              title="Unscheduled"
              tasks={tasks.unscheduled}
              onClick={handleClick}
            />
          </Accordion>
        </div>
      </SideScrollerContent>
    </SideScrollerLayout>
  );
}

function AccordionItem({ index, active, title, tasks, onClick }) {
  return (
    <>
      <Accordion.Title active={active} index={index} onClick={onClick}>
        <Header>
          <Icon color="grey" name={active ? "chevron down" : "chevron up"} />{" "}
          {title}
        </Header>
      </Accordion.Title>
      <Accordion.Content active={active}>
        <MainCards tasks={tasks} />
      </Accordion.Content>
    </>
  );
}

function MainCards({ tasks }) {
  return (
    <>
      <Card.Group>
        {tasks.length === 0 && (
          <Card>
            <Card.Content extra>No tasks found</Card.Content>
          </Card>
        )}
        {tasks.map((task) => (
          <MainCard key={task.id} task={task} />
        ))}
      </Card.Group>
    </>
  );
}

function MainCard({ task }) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Card
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{
        scale: isHovering ? "1.05" : "1",
        transition: "all 100ms ease-in-out",
      }}
    >
      <Card.Content>
        <Card.Header>
          <TaskProviderIcon size={16} provider={task.provider} />{" "}
          {task.name.substring(0, 100)} {task.name.length > 100 ? "..." : ""}
        </Card.Header>
        <Card.Meta>
          {task.due_date.length > 0 ? <>{task.due_date}</> : "No due date"}
        </Card.Meta>
      </Card.Content>
      <Card.Content extra>
        <div style={{ marginTop: "8px" }}>
          <a href={task.url} target="_blank" rel="noreferrer">
            <Button basic primary size="mini" onClick={() => {}}>
              <Icon name="linkify" /> More Details
            </Button>
          </a>
        </div>
      </Card.Content>
    </Card>
  );
}

function UpcomingTask({ task }) {
  return (
    <SideScrollerSidebarItem>
      <div>
        <Menu.Header>
          <TaskProviderIcon provider={task.provider} />
          {task.name}
        </Menu.Header>

        {task.due_date.length > 0 && (
          <div style={{ marginTop: "8px" }}>
            {task.due_date} - {task.due_date_human}
          </div>
        )}

        <div style={{ marginTop: "8px" }}>
          <a href={task.url} target="_blank" rel="noreferrer">
            <Button basic primary size="mini" onClick={() => {}}>
              <Icon name="linkify" /> More Details
            </Button>
          </a>
        </div>
      </div>
    </SideScrollerSidebarItem>
  );
}

function TaskProviderIcon({ provider, size = 12 }) {
  const style = { marginRight: "4px" };
  switch (provider) {
    case "clickup":
      return <SiClickup style={style} />;
    case "asana":
      return <SiAsana style={style} />;
    default:
      return null;
  }
}

export default Projects;
