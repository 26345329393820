import { Card, DimmerDimmable, Statistic } from "semantic-ui-react";

function StatCard({ label, value, colour, loading }) {
  return (
    <DimmerDimmable
      as={Card}
      blurring
      dimmed={loading ? true : false}
      style={{ background: colour, minHeight: "104px" }}
    >
      <Card.Content
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Statistic inverted>
          {label && <Statistic.Label>{label}</Statistic.Label>}
          <Statistic.Value>{loading ? "N/A" : value}</Statistic.Value>
        </Statistic>
      </Card.Content>
    </DimmerDimmable>
  );
}

export default StatCard;
