import axios from "axios";
import config from "../config";

export function searchContacts(query = {}) {
  return axios.get(`${config.api_url}/contacts`, { params: query });
}

export function lookupByCallerid(callerid, timeout = 30000) {
  return axios.get(`${config.api_url}/contacts/callerid/${callerid}`, {
    timeout: timeout,
  });
}

export function setFavourite(id) {
  return axios.post(`${config.api_url}/contacts/favourites/${id}`);
}

export function removeFavourite(id) {
  return axios.delete(`${config.api_url}/contacts/favourites/${id}`);
}
