import React, { useContext, useEffect, useState } from "react";

const InstallPromptContext = React.createContext();
const InstallPromptProvider = ({ children }) => {
  const [prompt, setPrompt] = useState();

  useEffect(() => {
    const ready = window.addEventListener("beforeinstallprompt", (e) => {
      // Prevents the default mini-infobar or install dialog from appearing on mobile
      e.preventDefault();
      setPrompt(e);
    });

    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
    };
  }, []);

  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt();
    }

    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event',
      ),
    );
  };

  const defaultContext = {
    prompt,
    promptToInstall,
  };
  return (
    <InstallPromptContext.Provider value={defaultContext}>
      {children}
    </InstallPromptContext.Provider>
  );
};

function useInstallPrompt() {
  return useContext(InstallPromptContext);
}

export { InstallPromptProvider, useInstallPrompt };
