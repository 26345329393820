import { useInfiniteQuery } from "@tanstack/react-query";
import { Navigate } from "react-router-dom";
import { Container, Segment } from "semantic-ui-react";
import { getEngageChats } from "../api/engage";
import PageLoader from "../components/PageLoader";
import { toast } from "react-toastify";

function Engage() {
  const chatsQuery = useInfiniteQuery({
    queryKey: ["engage-chats"],
    queryFn: async ({ pageParam = 1 }) => {
      return getEngageChats({ page: pageParam });
    },
    getNextPageParam: (lastPage) =>
      lastPage.data.meta.current_page < lastPage.data.meta.last_page
        ? lastPage.data.meta.current_page + 1
        : undefined,

    keepPreviousData: true,
    cacheTime: 60 * 60 * 1000,
  });

  if (chatsQuery.isLoading) return <PageLoader>Loading...</PageLoader>;

  if (chatsQuery.isError) {
    toast("Failed to load Engage chats", { type: "error" });
    return null;
  }

  if (chatsQuery?.data?.pages[0]?.data.data.length > 0) {
    return (
      <Navigate
        to={`/engage/${chatsQuery?.data?.pages[0]?.data?.data[0].id}`}
      />
    );
  }

  return (
    <Container>
      <Segment basic style={{ height: "200px", textAlign: "center" }}>
        You do not have Engage chats to display
      </Segment>
    </Container>
  );
}

export default Engage;
