import { Container } from "semantic-ui-react";
import IpecsLoginOptions from "../components/IpecsLoginOptions";
import LicenseKeyPrompt from "../components/LicenseKeyPrompt";
import { useAuth } from "../contexts/AuthContext";

const style = {
  container: {
    padding: "3rem",
  },
};

function IpecsLogin() {
  const { apiUser } = useAuth();

  return (
    <Container fluid style={style.container}>
      {apiUser.has_connect ? <IpecsLoginOptions /> : <LicenseKeyPrompt />}
    </Container>
  );
}

export default IpecsLogin;
