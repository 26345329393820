import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "semantic-ui-less/semantic.less";
import App from "./App";
import { createIDBPersister } from "./IDBPersister";
import config from "./config";
import { AuthProvider } from "./contexts/AuthContext";
import { InstallPromptProvider } from "./contexts/InstallPromptContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.headers.common = {
  "X-API-VERSION": config.api_version,
  "X-APP-URL": config.app_url,
  Accept: "application/json",
};
axios.interceptors.response.use(undefined, function (error) {
  if (error.response?.status === 401) {
    const redirectUrl = error?.response?.data?.redirect_url;
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }

  return Promise.reject(error);
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5000, // 5 seconds
      cacheTime: 1000 * 60 * 60 * 24 * 2, // 2 days, this should be higher than maxAge on persistent cache
    },
  },
});

const persister = createIDBPersister("connect");
const doNotPersistQueries = [
  "calendar-events", // calendar event does some stuff with moment which causes as issue
  "ipecs-login-options", // prevent double login in ipecs login options
];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PersistQueryClientProvider
    client={queryClient}
    buster={config.api_version}
    persistOptions={{
      persister: persister,
      maxAge: 1000 * 60 * 60 * 24 * 5, // 3 days
      hydrateOptions: {},
      dehydrateOptions: {
        shouldDehydrateQuery: ({ queryKey, state }) => {
          return (
            state.status === "success" &&
            !doNotPersistQueries.includes(queryKey[0] ?? "")
          );
        },
      },
    }}
  >
    <InstallPromptProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </InstallPromptProvider>
    <ToastContainer
      style={{ zIndex: 999999999 }}
      position="top-center"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
  </PersistQueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
