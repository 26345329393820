import { useEffect, useState } from "react";
import { Popup } from "semantic-ui-react";
import { usePresence } from "../contexts/PresenceContext";
import { getFullPresenceDisplay } from "../helpers/presence-helper";
import PresenceIndicatorDot from "./PresenceIndicatorDot";

function PresenceIndicator({ userId, user, size = "mini", ...otherProps }) {
  const [content, setContent] = useState();
  const [presence, setPresence] = useState();
  const { getPresenceByUserId } = usePresence();

  useEffect(() => {
    const fetch = async () => {
      // the context listes to events so will have the latest data, use
      // this if it's present, it not fallback to the presence on the user
      const _valueFromContext = await getPresenceByUserId(userId);
      const _presence =
        _valueFromContext?.presence ?? user?.presence ?? "unknown";
      const _message = _valueFromContext?.statusMessage ?? user?.statusMessage;
      setPresence(_presence);
      setContent(getFullPresenceDisplay(_presence, _message));
    };

    fetch();
  }, [userId, getPresenceByUserId, user]);

  if (!presence) return null;

  return (
    <Popup
      size={size}
      content={content}
      position="right center"
      pinned
      trigger={<PresenceIndicatorDot presence={presence} {...otherProps} />}
    />
  );
}

export default PresenceIndicator;
